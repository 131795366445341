import type * as React from 'react';
export const UnicornIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={241}
    height={124}
    fill="none"
    viewBox="0 0 241 124"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M108.847 105.38c-9.4903 8.486-16.5246 10.942-22.3307 5.918-3.908-3.461-.67-11.166 3.9079-15.0736"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M108.847 105.38c-9.4903 8.486-16.5246 10.942-22.3307 5.918-3.908-3.461-.67-11.166 3.9079-15.0736"
    />
    <path
      fill="#fff"
      d="M157.864 97.6759c.67.8933 1.452 1.7865 2.345 2.5681 4.578 4.02 10.496 4.801 13.176 1.786 2.679-3.0142 1.004-8.8203-3.573-12.8399-.894-.7816-1.899-1.4515-2.792-2.0098"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M157.864 97.6759c.67.8933 1.452 1.7865 2.345 2.5681 4.578 4.02 10.496 4.801 13.176 1.786 2.679-3.0142 1.004-8.8203-3.573-12.8399-.894-.7816-1.899-1.4515-2.792-2.0098"
    />
    <path
      fill="#fff"
      d="M57.0488 34.4226c7.741 0 14.0163-2.5789 14.0163-5.7601 0-3.1813-6.2753-5.7602-14.0163-5.7602s-14.0163 2.5789-14.0163 5.7602c0 3.1812 6.2753 5.7601 14.0163 5.7601Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M57.0488 34.4226c7.741 0 14.0163-2.5789 14.0163-5.7601 0-3.1813-6.2753-5.7602-14.0163-5.7602s-14.0163 2.5789-14.0163 5.7602c0 3.1812 6.2753 5.7601 14.0163 5.7601Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M57.0488 34.4226c7.741 0 14.0163-2.5789 14.0163-5.7601 0-3.1813-6.2753-5.7602-14.0163-5.7602s-14.0163 2.5789-14.0163 5.7602c0 3.1812 6.2753 5.7601 14.0163 5.7601Z"
    />
    <path
      fill="#fff"
      d="M71.1605 28.6624c0 3.168-6.2402 5.7601-14.0163 5.7601-7.7762 0-14.0163-2.5921-14.0163-5.7601v6.7201c0 3.1681 6.2401 5.7601 14.0163 5.7601 7.7761 0 14.0163-2.592 14.0163-5.7601v-6.7201Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M71.1605 28.6624c0 3.168-6.2402 5.7601-14.0163 5.7601-7.7762 0-14.0163-2.5921-14.0163-5.7601v6.7201c0 3.1681 6.2401 5.7601 14.0163 5.7601 7.7761 0 14.0163-2.592 14.0163-5.7601v-6.7201Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M71.1605 28.6624c0 3.168-6.2402 5.7601-14.0163 5.7601-7.7762 0-14.0163-2.5921-14.0163-5.7601v6.7201c0 3.1681 6.2401 5.7601 14.0163 5.7601 7.7761 0 14.0163-2.592 14.0163-5.7601v-6.7201Z"
    />
    <path
      fill="#fff"
      d="M25.8706 73.8297c2.4515-1.8838.7227-8.2468-3.8613-14.212-4.5839-5.9653-10.2873-9.2739-12.7388-7.3901-2.4515 1.8838-.7227 8.2468 3.8613 14.212 4.5839 5.9653 10.2873 9.2739 12.7388 7.3901Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M25.8706 73.8297c2.4515-1.8838.7227-8.2468-3.8613-14.212-4.5839-5.9653-10.2873-9.2739-12.7388-7.3901-2.4515 1.8838-.7227 8.2468 3.8613 14.212 4.5839 5.9653 10.2873 9.2739 12.7388 7.3901Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M25.8706 73.8297c2.4515-1.8838.7227-8.2468-3.8613-14.212-4.5839-5.9653-10.2873-9.2739-12.7388-7.3901-2.4515 1.8838-.7227 8.2468 3.8613 14.212 4.5839 5.9653 10.2873 9.2739 12.7388 7.3901Z"
    />
    <path
      fill="#fff"
      d="M25.8561 73.7854c-2.4259 1.8661-8.2107-1.3995-12.7825-7.3709-4.5719-5.9714-6.3446-12.316-3.8255-14.1821l-5.225 3.9187c-2.4258 1.8661-.7463 8.304 3.8255 14.2754 4.5719 5.9714 10.2633 9.3303 12.7825 7.3709l5.225-4.012Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M25.8561 73.7854c-2.4259 1.8661-8.2107-1.3995-12.7825-7.3709-4.5719-5.9714-6.3446-12.316-3.8255-14.1821l-5.225 3.9187c-2.4258 1.8661-.7463 8.304 3.8255 14.2754 4.5719 5.9714 10.2633 9.3303 12.7825 7.3709l5.225-4.012Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M25.8561 73.7854c-2.4259 1.8661-8.2107-1.3995-12.7825-7.3709-4.5719-5.9714-6.3446-12.316-3.8255-14.1821l-5.225 3.9187c-2.4258 1.8661-.7463 8.304 3.8255 14.2754 4.5719 5.9714 10.2633 9.3303 12.7825 7.3709l5.225-4.012Z"
    />
    <path
      fill="#fff"
      d="M206.686 97.1378c6.008-4.4031 9.412-9.974 7.603-12.4432-1.81-2.4691-8.147-.9014-14.156 3.5016-6.008 4.403-9.412 9.974-7.602 12.4428 1.809 2.47 8.147.902 14.155-3.5012Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M206.686 97.1378c6.008-4.4031 9.412-9.974 7.603-12.4432-1.81-2.4691-8.147-.9014-14.156 3.5016-6.008 4.403-9.412 9.974-7.602 12.4428 1.809 2.47 8.147.902 14.155-3.5012Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M206.686 97.1378c6.008-4.4031 9.412-9.974 7.603-12.4432-1.81-2.4691-8.147-.9014-14.156 3.5016-6.008 4.403-9.412 9.974-7.602 12.4428 1.809 2.47 8.147.902 14.155-3.5012Z"
    />
    <path
      fill="#fff"
      d="M214.316 84.6992c1.848 2.4943-1.57 8.037-7.575 12.4712-6.005 4.4346-12.379 6.0046-14.134 3.5106l3.788 5.265c1.847 2.495 8.129.924 14.134-3.51 6.004-4.4342 9.422-9.9769 7.575-12.4712l-3.788-5.2656Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M214.316 84.6992c1.848 2.4943-1.57 8.037-7.575 12.4712-6.005 4.4346-12.379 6.0046-14.134 3.5106l3.788 5.265c1.847 2.495 8.129.924 14.134-3.51 6.004-4.4342 9.422-9.9769 7.575-12.4712l-3.788-5.2656Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M214.316 84.6992c1.848 2.4943-1.57 8.037-7.575 12.4712-6.005 4.4346-12.379 6.0046-14.134 3.5106l3.788 5.265c1.847 2.495 8.129.924 14.134-3.51 6.004-4.4342 9.422-9.9769 7.575-12.4712l-3.788-5.2656Z"
    />
    <path
      fill="#fff"
      d="M234.919 33.2495c2.375-2.2367-.113-8.7334-5.555-14.5109-5.443-5.7775-11.779-8.6479-14.154-6.4113-2.374 2.2367.113 8.7335 5.556 14.511 5.442 5.7775 11.779 8.6479 14.153 6.4112Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M234.919 33.2495c2.375-2.2367-.113-8.7334-5.555-14.5109-5.443-5.7775-11.779-8.6479-14.154-6.4113-2.374 2.2367.113 8.7335 5.556 14.511 5.442 5.7775 11.779 8.6479 14.153 6.4112Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M234.919 33.2495c2.375-2.2367-.113-8.7334-5.555-14.5109-5.443-5.7775-11.779-8.6479-14.154-6.4113-2.374 2.2367.113 8.7335 5.556 14.511 5.442 5.7775 11.779 8.6479 14.153 6.4112Z"
    />
    <path
      fill="#fff"
      d="M235.029 33.2804c-2.363 2.264-8.761-.5906-14.175-6.3983-5.414-5.8077-7.973-12.3045-5.611-14.5685l-5.02 4.7249c-2.363 2.264.098 8.7608 5.611 14.5685 5.512 5.8077 11.812 8.6623 14.175 6.3983l5.02-4.7249Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M235.029 33.2804c-2.363 2.264-8.761-.5906-14.175-6.3983-5.414-5.8077-7.973-12.3045-5.611-14.5685l-5.02 4.7249c-2.363 2.264.098 8.7608 5.611 14.5685 5.512 5.8077 11.812 8.6623 14.175 6.3983l5.02-4.7249Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M235.029 33.2804c-2.363 2.264-8.761-.5906-14.175-6.3983-5.414-5.8077-7.973-12.3045-5.611-14.5685l-5.02 4.7249c-2.363 2.264.098 8.7608 5.611 14.5685 5.512 5.8077 11.812 8.6623 14.175 6.3983l5.02-4.7249Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.699 103.929c-17.865 8.374-53.8176 16.078-68.7795-20.5451C73.23 66.4123 82.944 37.3819 121.13 29.4543c27.356-5.5828 47.007 10.8306 51.697 27.8022 4.578 16.86-6.476 37.4047-26.128 46.6725Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M136.873 56.9216c0-2.2331-1.452-4.3545-3.796-4.9128-2.68-.6699-5.472.8933-6.141 3.6846l-.335 1.4516"
    />
    <path
      fill="#fff"
      d="M131.956 63.4428c.652-2.5702-1.775-5.4033-5.422-6.328-3.646-.9246-7.13.4095-7.782 2.9797-.651 2.5702 1.776 5.4033 5.422 6.3279 3.646.9246 7.13-.4094 7.782-2.9796Z"
    />
    <path
      fill="#F46147"
      fillOpacity={0.5}
      d="M131.956 63.4428c.652-2.5702-1.775-5.4033-5.422-6.328-3.646-.9246-7.13.4095-7.782 2.9797-.651 2.5702 1.776 5.4033 5.422 6.3279 3.646.9246 7.13-.4094 7.782-2.9796Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M153.956 61.2762c1.117-2.0098 3.35-3.1264 5.695-2.4564 2.679.6699 4.354 3.4613 3.684 6.141L163 66.4123"
    />
    <path
      fill="#fff"
      d="M168.14 72.4226c.622-2.4506-1.733-5.1623-5.26-6.0566-3.527-.8942-6.889.3675-7.511 2.8181-.621 2.4507 1.734 5.1623 5.261 6.0566 3.526.8943 6.889-.3674 7.51-2.8181Z"
    />
    <path
      fill="#F46147"
      fillOpacity={0.5}
      d="M168.14 72.4226c.622-2.4506-1.733-5.1623-5.26-6.0566-3.527-.8942-6.889.3675-7.511 2.8181-.621 2.4507 1.734 5.1623 5.261 6.0566 3.526.8943 6.889-.3674 7.51-2.8181Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M120.991 62.8996c.757.8834 1.993 1.6735 3.552 2.0688 1.559.3954 3.022.2899 4.109-.1261 1.091-.4177 1.677-1.0832 1.85-1.7683.174-.685-.024-1.5492-.784-2.4364-.757-.8834-1.993-1.6735-3.552-2.0688-1.559-.3954-3.023-.2899-4.109.1261-1.091.4177-1.677 1.0832-1.851 1.7683-.173.685.025 1.5492.785 2.4364Zm-2.278 1.9519c-1.203-1.4031-1.893-3.2406-1.414-5.1257.478-1.8852 1.96-3.1719 3.685-3.8325 1.73-.6625 3.832-.7617 5.919-.2324 2.087.5292 3.888 1.618 5.093 3.0248 1.202 1.4031 1.892 3.2406 1.414 5.1257-.478 1.8852-1.96 3.1719-3.686 3.8325-1.73.6625-3.831.7617-5.918.2324-2.087-.5292-3.888-1.618-5.093-3.0248ZM157.576 71.8125c.728.8376 1.918 1.5929 3.422 1.9742 1.504.3814 2.911.2847 3.949-.1051 1.045-.3921 1.583-1.0119 1.739-1.6277.156-.6159-.021-1.417-.753-2.2597-.727-.8376-1.918-1.5929-3.422-1.9743-1.503-.3813-2.91-.2846-3.949.1052-1.045.3921-1.582 1.0118-1.739 1.6277-.156.6158.022 1.417.753 2.2597Zm-2.264 1.9671c-1.172-1.3494-1.862-3.1294-1.397-4.9642.466-1.8348 1.92-3.0712 3.593-3.6991 1.68-.6302 3.718-.7172 5.741-.2043 2.023.513 3.773 1.5607 4.949 2.9151 1.172 1.3493 1.862 3.1294 1.396 4.9642-.465 1.8348-1.919 3.0712-3.593 3.6991-1.679.6301-3.717.7172-5.74.2042-2.023-.5129-3.773-1.5606-4.949-2.915Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M83.391 75.5681c-9.6024 2.1215-15.5201 6.2527-14.0686 12.7287 1.1166 5.1362 9.4907 7.481 15.5201 2.6798"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M83.391 75.5681c-9.6024 2.1215-15.5201 6.2527-14.0686 12.7287 1.1166 5.1362 9.4907 7.481 15.5201 2.6798"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.347}
      d="M101.813 72.5535c-5.1361 8.3741-4.8011 15.1851 1.452 17.9765 4.689 2.2331 12.728-3.0147 14.738-7.3693"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m44.4244 101.357-30.0354 8.374M63.8538 111.182l-24.1176 10.161"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.347}
      d="M104.125 82.5c-1.947 1.947-2.206 4.5594-1.021 8.0301"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M148.374 65.519c-.67 2.2332-3.015 3.4614-5.248 2.7914-2.233-.6699-3.461-3.0147-2.792-5.2478"
    />
    <path
      fill="#fff"
      d="M146.812 49.2175c-3.573-3.1264-3.573-8.7092 0-11.9472L184.328 3.327c.893-.8932 2.345-.8932 3.35 0 .893.8933 1.116 2.2332.335 3.3497l-29.366 41.2009c-2.68 3.9079-8.262 4.5779-11.835 1.3399Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.25}
      d="M146.812 49.2175c-3.573-3.1264-3.573-8.7092 0-11.9472L184.328 3.327c.893-.8932 2.345-.8932 3.35 0 .893.8933 1.116 2.2332.335 3.3497l-29.366 41.2009c-2.68 3.9079-8.262 4.5779-11.835 1.3399Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.812 49.2175c-3.573-3.1264-3.573-8.7092 0-11.9472L184.328 3.327c.893-.8932 2.345-.8932 3.35 0 .893.8933 1.116 2.2332.335 3.3497l-29.366 41.2009c-2.68 3.9079-8.262 4.5779-11.835 1.3399Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.902}
      d="M145.027 39.7083c3.24 3.6718 11.879 3.7798 19.223-.324"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.972}
      d="M153.959 30.9262c3.097 3.5396 10.84 2.4335 17.809-1.4379"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.919}
      d="M165.906 20.1466c2.39 2.716 6.301 3.4764 11.407 1.3037"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.866}
      d="M176.959 10.2479c1.494 1.7069 4.267 3.2004 7.041 1.8136"
    />
  </svg>
);

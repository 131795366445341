import type * as React from 'react';
export const ArigatouGiftIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={110}
    height={164}
    fill="none"
    viewBox="0 0 110 164"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#000"
      d="M55.5676 161.722c27.4568 0 49.7154-2.855 49.7154-6.377 0-3.521-22.2586-6.376-49.7154-6.376-27.4567 0-49.7148 2.855-49.7148 6.376 0 3.522 22.2581 6.377 49.7148 6.377Z"
    />
    <path
      fill="#FEE3B5"
      d="M102.492 99.254c0-40.549-21.0219-63.5633-46.9254-63.5633-25.9035 0-46.9252 22.9146-46.9252 63.5633 0 26.302 8.7673 41.346 22.018 48.719.6974 4.483 3.0885 7.272 6.2766 7.671 2.3911.398 4.7822-.697 6.6751-2.989 3.7859.797 7.7711 1.096 11.9555 1.096 4.0848 0 8.07-.398 11.8559-1.096 1.8929 2.292 4.284 3.387 6.6751 2.989 3.1881-.498 5.5792-3.188 6.2766-7.671 13.2507-7.373 22.1178-22.417 22.1178-48.719Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M102.492 99.254c0-40.549-21.0219-63.5633-46.9254-63.5633-25.9035 0-46.9252 22.9146-46.9252 63.5633 0 26.302 8.7673 41.346 22.018 48.719.6974 4.483 3.0885 7.272 6.2766 7.671 2.3911.398 4.7822-.697 6.6751-2.989 3.7859.797 7.7711 1.096 11.9555 1.096 4.0848 0 8.07-.399 11.8559-1.096 1.8929 2.292 4.284 3.387 6.6751 2.989 3.1881-.498 5.5792-3.188 6.2766-7.671 13.2507-7.373 22.1178-22.417 22.1178-48.719Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M55.5674 6.0104c38.7557 0 52.1056 20.0254 52.1056 44.833 0 24.8076-13.3499 44.8331-52.1056 44.8331-38.7557 0-52.106-20.0255-52.106-44.8331s13.3503-44.833 52.106-44.833Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M47.3343 52.3509c-.4982-1.9926-2.2915-3.3874-4.3837-3.2878-2.4907.0996-4.4833 2.0922-4.3837 4.583v1.2951"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.359}
      d="M38.7641 63.5049c3.3554-.0777 6.0312-2.0581 5.9764-4.4234-.0548-2.3653-2.8193-4.2198-6.1747-4.1421-3.3555.0777-6.0312 2.0581-5.9764 4.4234.0548 2.3653 2.8193 4.2198 6.1747 4.1421Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M63.0756 51.9523c.3985-1.9926 2.1918-3.4871 4.284-3.4871 2.4907-.0996 4.4833 1.893 4.5829 4.3837v1.2952"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.359}
      d="M72.1359 62.4107c3.2454-.0752 5.834-1.9643 5.7818-4.2196-.0522-2.2553-2.7255-4.0227-5.9709-3.9475-3.2454.0751-5.834 1.9643-5.7818 4.2196.0522 2.2553 2.7255 4.0226 5.9709 3.9475Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M59.2887 56.9339c0 2.0922-1.6937 3.6862-3.7859 3.6862s-3.6863-1.6937-3.6863-3.7859"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M54.4827 27.8704c-.1993-4.2841-2.3911-8.3688-6.177-10.6603-3.8856-2.3911-8.4685-2.4907-12.354-.6974.1992 4.284 2.3911 8.3688 6.177 10.6603 3.8855 2.2915 8.4684 2.4907 12.354.6974Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M54.482 27.8706c5.6788-2.4907 9.3651-6.8744 9.5644-11.9554.0996-5.0811-3.3874-9.5644-8.867-12.4536-5.5792 2.5903-9.3651 6.8743-9.5644 11.9554-.0996 5.0811 3.3874 9.5644 8.867 12.4536Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M54.4822 27.8704c3.8855 1.8929 8.6677 1.9926 12.8521-.2989s6.8744-6.2766 7.3726-10.5607c-3.8856-1.8929-8.6678-1.9926-12.8522.2989-4.1844 2.1918-6.7747 6.2766-7.3725 10.5607Z"
    />
    <path
      fill="#AC9EF2"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m80.8216 107.554-24.888 6.601-23.075-6.601-2.5044-34.3971 29.0361-4.948 25.5793 7.0686-4.148 32.2765Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.1833 74.7504 58.3242 82.346l-27.97-9.4249M58.2688 82.3459l-2.4274 31.8091"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M38.0181 87.9671c.7188.6801.7335 1.7971.0329 2.4949l-2.4328 2.4629c-.7007.6977-1.8514.712-2.5702.0319-.7189-.6801-.7336-1.7971-.033-2.4948l2.4329-2.4629c.7006-.6978 1.8513-.7121 2.5702-.032ZM37.7376 96.0764c-.5158-.8359-.2357-1.9195.6254-2.4201l2.5378-1.3308c.8612-.5006 1.9774-.2288 2.4932.6071.5158.836.2357 1.9195-.6254 2.4201l-2.5378 1.3308c-.8612.5006-1.9774.2288-2.4932-.6071ZM36.7619 100.673c.155-.9627 1.0846-1.6212 2.0764-1.4707l2.0672.5224c.9917.1505 1.6701 1.0533 1.5151 2.0153-.155.963-1.0847 1.621-2.0764 1.471l-2.0672-.522c-.9918-.151-1.6701-1.053-1.5151-2.016Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M20.3913 120.016c4.4976-2.022 7.0737-5.341 8.7245-11.833 8.2981-3.567 11.4052-10.265 9.3849-13.8839-2.7488-4.8819-13.4644-3.1245-20.4862 3.294"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.4}
      d="M20.3913 120.016c4.4976-2.022 7.0737-5.341 8.7245-11.833 8.2981-3.567 11.4052-10.265 9.3849-13.8839-2.7488-4.8819-13.4644-3.1245-20.4862 3.294"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M29.5609 89.9681c4.1037-.6643 8.5758.0059 10.5346 3.4848l.0031.0056c1.427 2.556.888 5.8257-.855 8.6975-1.6687 2.749-4.557 5.382-8.5828 7.276-.8296 2.99-1.9028 5.414-3.3675 7.378-1.6169 2.168-3.6435 3.685-6.1384 4.807l-1.5273-3.202c2.0027-.9 3.513-2.054 4.7212-3.674 1.2279-1.646 2.2095-3.863 3.0023-6.981l.2136-.84.8152-.351c3.8484-1.654 6.3872-3.989 7.7317-6.204 1.3787-2.2717 1.3843-4.1593.7925-5.2226-.7914-1.4014-3.051-2.2918-6.7452-1.6938-3.5333.5721-7.6409 2.4513-10.8984 5.4289l-2.4921-2.5688c3.7643-3.441 8.5253-5.6497 12.7925-6.3406ZM74.461 87.0583c-.7188.6801-.7336 1.7971-.0329 2.4948l1.9973 3.3717c.7006.6978 1.8514.7121 2.5702.032.7188-.6801.7336-1.7971.0329-2.4949l-1.9973-3.3717c-.7006-.6977-1.8513-.7121-2.5702-.0319ZM74.6697 95.3295c.2857-.9341-.2629-1.9161-1.2252-2.1934l-3.0376-1.2351c-.9623-.2772-1.974.2552-2.2596 1.1894-.2857.9341.2629 1.9161 1.2252 2.1933l3.0376 1.2351c.9623.2773 1.974-.2552 2.2596-1.1893ZM75.3311 101.041c-.0197-.975-.8493-1.7488-1.8529-1.7297l-2.2614.2403c-1.0036.0191-1.8012.8244-1.7816 1.7984.0197.974.8493 1.749 1.8529 1.73l2.2614-.241c1.0036-.019 1.8012-.824 1.7816-1.798Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M91.6525 120.016c-4.4976-2.022-7.0737-5.341-8.7245-11.833-8.2981-3.567-11.4052-10.265-9.3849-13.8839 2.7489-4.8819 13.4645-3.1245 20.4862 3.294"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.4}
      d="M91.6525 120.016c-4.4976-2.022-7.0737-5.341-8.7245-11.833-8.2981-3.567-11.4052-10.265-9.3849-13.8839 2.7489-4.8819 13.4645-3.1245 20.4862 3.294"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M82.4831 89.9681c-4.1038-.6643-8.5758.0059-10.5347 3.4848l-.0031.0056c-1.4269 2.556-.888 5.8257.8551 8.6975 1.6687 2.749 4.5569 5.382 8.5827 7.276.8296 2.99 1.9029 5.414 3.3675 7.378 1.617 2.168 3.6435 3.685 6.1384 4.807l1.5274-3.202c-2.0028-.9-3.5131-2.054-4.7213-3.674-1.2278-1.646-2.2094-3.863-3.0023-6.981l-.2136-.84-.8151-.351c-3.8484-1.654-6.3873-3.989-7.7318-6.204-1.3787-2.2717-1.3843-4.1593-.7925-5.2226.7914-1.4014 3.0511-2.2918 6.7453-1.6938 3.5333.5721 7.6409 2.4513 10.8983 5.4289l2.4921-2.5688c-3.7643-3.441-8.5253-5.6497-12.7924-6.3406Z"
      clipRule="evenodd"
    />
  </svg>
);

import type * as React from 'react';
export const DrinkIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={163}
    height={112}
    fill="none"
    viewBox="0 0 163 112"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M52.954 77.0675V52.7319C52.954 50.704 63.0939 48 85.4015 48c23.6595 0 31.0955 2.028 31.0955 4.7319v24.3356c-2.028-2.7039-12.979-5.4079-32.4475-5.4079-19.4684 0-29.7435 3.38-31.0955 5.4079Z"
      opacity={0.5}
    />
    <path
      fill="#8C77EE"
      d="M119.201 102.695c0 3.244-15.435 5.874-34.4754 5.874-19.0403 0-34.4755-2.63-34.4755-5.874 0-3.2435 15.4352-5.8732 34.4755-5.8732 19.0404 0 34.4754 2.6297 34.4754 5.8732Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M116.607 101.551s-.003.003-.009.008c.006-.005.009-.008.009-.008Zm-2.668 1.144c-1.373-.416-3.162-.825-5.339-1.196-6.027-1.026-14.4695-1.6772-23.8744-1.6772-9.4049 0-17.8472.6512-23.874 1.6772-2.1774.371-3.966.78-5.3395 1.196 1.3735.417 3.1621.825 5.3395 1.196 6.0268 1.027 14.4691 1.678 23.874 1.678s17.8474-.651 23.8744-1.678c2.177-.371 3.966-.779 5.339-1.196Zm-61.0948-1.144c.0002 0 .0034.003.0092.008a.066.066 0 0 1-.0092-.008Zm0 2.289a.1236.1236 0 0 1 .0092-.009.1144.1144 0 0 1-.0092.009Zm63.7538-.009.009.009-.009-.009Zm-31.8724 4.738c19.0404 0 34.4754-2.63 34.4754-5.874 0-3.2435-15.435-5.8732-34.4754-5.8732-19.0403 0-34.4755 2.6297-34.4755 5.8732 0 3.244 15.4352 5.874 34.4755 5.874Z"
      clipRule="evenodd"
    />
    <path
      fill="#8C77EE"
      d="M117.738 76.9355c0 3.3138-14.774 6-32.9996 6-18.2254 0-33-2.6862-33-6 0-3.3137 14.7746-6 33-6 18.2256 0 32.9996 2.6863 32.9996 6Z"
    />
    <path
      fill="#5A3DE6"
      d="M52.954 77.4734v24.3356c0 2.028 10.1399 4.732 32.4475 4.732 23.6595 0 31.0955-2.028 31.0955-4.732V77.4734c-2.028 2.7039-12.979 5.4079-32.4475 5.4079-19.4684 0-29.7435-3.3799-31.0955-5.4079Z"
      opacity={0.5}
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m79.9936 102.674 30.3834-56.7214"
    />
    <path
      fill="#FFDC9B"
      d="M78.9502 16.6747c3.0519.5671 8.1001.8379 10.9344 4.3123 1.9333 2.4025 2.4118 6.8758 1.0514 9.5817 3.6333.1099 12.741.2417 16.419 3.7763 3.366 3.3028 2.668 8.5999 3.008 11.5171 7.99-9.9037 28.514-2.8417 26.836 5.8469 5.461-2.5727 14.982-2.7805 21.522 6.9139 1.793-18.5362-7.343-42.5912-26.912-50.7223-19.856-8.1814-42.2648-1.7017-52.8588 8.7741Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M77.8955 15.6081C88.9086 4.7179 111.955-1.9023 132.38 6.5137l.004.0017c10.204 4.2397 17.619 12.601 22.243 22.193 4.622 9.5868 6.511 20.5092 5.587 30.0588l-.402 4.1553-2.334-3.4608c-6.047-8.9631-14.711-8.7177-19.64-6.3958l-2.672 1.2589.56-2.9003c.29-1.4986-.333-3.0841-1.924-4.6018-1.592-1.518-4.009-2.804-6.79-3.5621-5.643-1.538-12.022-.7449-15.481 3.5433l-2.243 2.7808-.414-3.5488c-.081-.6888-.108-1.4919-.136-2.2829a51.6023 51.6023 0 0 0-.014-.4019c-.035-.9519-.083-1.9272-.216-2.8988-.268-1.9471-.858-3.7131-2.198-5.0312-1.475-1.4134-4.246-2.2817-7.408-2.7658-2.8602-.4379-5.7251-.5209-7.5928-.575a343.0352 343.0352 0 0 1-.4185-.0123l-2.3516-.0711 1.0568-2.1019c.5148-1.024.7282-2.5088.5584-4.0686-.1694-1.5561-.6974-2.9762-1.4352-3.8952-1.1456-1.402-2.785-2.2186-4.6335-2.7487-1.3204-.3786-2.5901-.577-3.8069-.7671-.5448-.0852-1.0791-.1687-1.6025-.2659l-2.8207-.5242 2.04-2.0172Zm4.3889.0972c.8529.1538 1.7546.3429 2.6281.5934 2.1453.6152 4.4458 1.6701 6.1344 3.7401l.0064.0078c1.1938 1.4836 1.8705 3.4996 2.0833 5.4551.1304 1.1978.0954 2.4524-.1461 3.6314 1.7835.0689 4.0704.2054 6.3654.5567 3.2151.4923 6.8351 1.4556 9.0391 3.5736l.005.0055.006.0055c2.022 1.9842 2.764 4.5159 3.074 6.7695.118.8621.176 1.7081.213 2.4892 4.59-3.3726 10.935-3.577 16.108-2.1669 3.139.8555 6.037 2.3452 8.072 4.2855 1.425 1.3596 2.497 3.0211 2.822 4.8727 5.325-1.66 12.774-1.1972 18.743 5.0924.107-8.0141-1.74-16.7774-5.513-24.6056-4.388-9.1017-11.325-16.8331-20.69-20.7247-17.948-7.3944-38.048-2.4168-48.9506 6.4188Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M124.967 13.5763c-10.381.3712-19.436 5.2188-27.6788 12.4107l-1.9723-2.2605C105.1 15.1901 116.371 9.5141 129.799 10.7124l.575.0513.392.4235c9.144 9.872 12.796 23.4205 11.585 34.1185l-2.981-.3372c1.081-9.5515-2.066-21.7342-10.007-30.855-7.312 4.48-10.278 12.0668-13.681 23.3512l-2.872-.8661c2.969-9.847 5.807-17.756 12.157-23.0223Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m116.607 49.965-.009.0086.009-.0086Zm-2.668 1.1447c-1.373-.4169-3.162-.8249-5.339-1.1959-6.027-1.0268-14.4695-1.6777-23.8744-1.6777-9.4049 0-17.8472.6509-23.874 1.6777-2.1774.371-3.966.779-5.3395 1.1959 1.3735.4168 3.1621.8249 5.3395 1.1959 6.0268 1.0267 14.4691 1.6777 23.874 1.6777s17.8474-.651 23.8744-1.6777c2.177-.371 3.966-.7791 5.339-1.1959ZM52.8442 49.965a.1205.1205 0 0 1 .0092.0086.1127.1127 0 0 1-.0092-.0086Zm0 2.2893a.1423.1423 0 0 1 .0092-.0086.0987.0987 0 0 1-.0092.0086Zm63.7538-.0085.009.0085-.009-.0085Zm-31.8724 4.7375c19.0404 0 34.4754-2.6297 34.4754-5.8736 0-3.2439-15.435-5.8736-34.4754-5.8736-19.0403 0-34.4755 2.6297-34.4755 5.8736 0 3.2439 15.4352 5.8736 34.4755 5.8736ZM117.615 100.457c-1.957 3.017-15.936 5.356-32.8898 5.356-16.9533 0-30.9319-2.339-32.8891-5.356-1.0305.557-1.5861 1.151-1.5861 1.766 0 3.244 15.4352 5.874 34.4755 5.874 19.0405 0 34.4755-2.63 34.4755-5.874 0-.615-.556-1.209-1.586-1.766Zm-.806 2.695c-.001-.002-.004-.004-.007-.007.006.007.01.01.01.01l-.003-.003Z"
      clipRule="evenodd"
    />
    <path
      fill="#3B2D81"
      fillRule="evenodd"
      d="M85.2033 80.1559c15.8057-.0128 29.0997-1.8741 33.1027-4.4048.772.4882 1.183 1.0016 1.183 1.5313 0 3.2439-15.435 5.8736-34.4751 5.8736-19.0403 0-34.4754-2.6297-34.4754-5.8736 0-.5353.4204-1.054 1.208-1.5468 1.9184 1.2258 6.0155 2.2955 11.5231 3.0754 5.814.8327 13.4003 1.345 21.7443 1.345l.1894-.0001Zm31.6917-1.7288s-.002-.0029-.009-.0086c.003.003.005.0052.007.0066.001.0013.002.002.002.002Z"
      clipRule="evenodd"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M117.997 51.934v49.727M51.75 52.355v49.096"
    />
    <path
      fill="#F7907E"
      d="M41.2391 75.6196A21.5 21.5 0 1 1 4 97.1196l37.2391-21.5Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M41.2391 75.6196a21.5004 21.5004 0 0 1 2.1479 16.3146 21.4995 21.4995 0 0 1-10.0175 13.0548 21.4996 21.4996 0 0 1-16.3146 2.148A21.4978 21.4978 0 0 1 4 97.1196l37.2391-21.5ZM8.3101 98.0953a18.5005 18.5005 0 0 0 23.5594 4.2957 18.4996 18.4996 0 0 0 8.0595-22.5509L8.31 98.0953Z"
      clipRule="evenodd"
    />
    <path
      fill="#F7907E"
      d="M56.6685 87.7719A21.4993 21.4993 0 0 1 35.067 109.17a21.5002 21.5002 0 0 1-21.398-21.6016l42.9995.2035Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M56.6685 87.7719a21.502 21.502 0 0 1-6.3691 15.1731A21.5002 21.5002 0 0 1 13.669 87.5684l42.9995.2035Zm-39.769 2.8118a18.5035 18.5035 0 0 0 5.126 10.1063 18.502 18.502 0 0 0 13.0557 5.48 18.5015 18.5015 0 0 0 18.3284-15.4134l-36.5101-.1729Z"
      clipRule="evenodd"
    />
  </svg>
);

import type * as React from 'react';
export const FailureMarkIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={64}
    height={70}
    fill="none"
    viewBox="0 0 64 70"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <rect width={55} height={3} x={5} y={63} fill="#1D1D1D" rx={1.5} />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M52.9013 51.9014c-2.1479 2.1479-5.472 2.3062-7.4246.3536L11.5355 18.3139c-1.9526-1.9527-1.7943-5.2768.3536-7.4247 2.1479-2.1478 5.472-2.3061 7.4246-.3535l33.9411 33.9411c1.9527 1.9526 1.7944 5.2768-.3535 7.4246Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M11.8891 51.9013c-2.1479-2.1479-2.3062-5.472-.3536-7.4246l33.9412-33.9412c1.9526-1.9526 5.2767-1.7943 7.4246.3536s2.3062 5.472.3535 7.4246L19.3137 52.2548c-1.9526 1.9527-5.2767 1.7944-7.4246-.3535Z"
      clipRule="evenodd"
    />
    <rect
      width={5}
      height={52}
      x={15.778}
      y={51.548}
      fill="#F9B0A3"
      rx={2.5}
      transform="rotate(-135 15.778 51.548)"
    />
    <rect
      width={5}
      height={52}
      x={12.243}
      y={14.778}
      fill="#F9B0A3"
      rx={2.5}
      transform="rotate(-45 12.243 14.778)"
    />
  </svg>
);

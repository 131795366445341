import type * as React from 'react';
export const CoinsIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={58}
    height={38}
    fill="none"
    viewBox="0 0 58 38"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M35.3121 20.138c.1045-3.4995-6.7138-6.5424-15.2292-6.7966-8.5153-.2542-15.503 2.3766-15.6075 5.876-.1044 3.4995 6.7139 6.5424 15.2292 6.7966s15.5031-2.3766 15.6075-5.876Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M35.3121 20.138c.1045-3.4995-6.7138-6.5424-15.2292-6.7966-8.5153-.2542-15.503 2.3766-15.6075 5.876-.1044 3.4995 6.7139 6.5424 15.2292 6.7966s15.5031-2.3766 15.6075-5.876Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M35.3121 20.138c.1045-3.4995-6.7138-6.5424-15.2292-6.7966-8.5153-.2542-15.503 2.3766-15.6075 5.876-.1044 3.4995 6.7139 6.5424 15.2292 6.7966s15.5031-2.3766 15.6075-5.876Z"
    />
    <path
      fill="#fff"
      d="M35.4184 20.0755c-.0747 3.5112-7.0971 6.2007-15.6137 5.9019-8.5165-.2989-15.3895-3.2871-15.3148-6.7983l-.2241 7.3959c-.0747 3.5112 6.7236 6.5742 15.3148 6.7983 8.5913.2241 15.539-2.3906 15.6137-5.9018l.2241-7.396Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M35.4184 20.0755c-.0747 3.5112-7.0971 6.2007-15.6137 5.9019-8.5165-.2989-15.3895-3.2871-15.3148-6.7983l-.2241 7.3959c-.0747 3.5112 6.7236 6.5742 15.3148 6.7983 8.5913.2241 15.539-2.3906 15.6137-5.9018l.2241-7.396Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M35.4184 20.0755c-.0747 3.5112-7.0971 6.2007-15.6137 5.9019-8.5165-.2989-15.3895-3.2871-15.3148-6.7983l-.2241 7.3959c-.0747 3.5112 6.7236 6.5742 15.3148 6.7983 8.5913.2241 15.539-2.3906 15.6137-5.9018l.2241-7.396Z"
    />
    <path
      fill="#fff"
      d="M53.3666 27.4034c2.5484-2.4007-.1212-9.3736-5.9627-15.5746-5.8415-6.201-12.6428-9.2818-15.1912-6.8812-2.5483 2.4006.1213 9.3736 5.9628 15.5746 5.8415 6.201 12.6428 9.2818 15.1911 6.8812Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M53.3666 27.4034c2.5484-2.4007-.1212-9.3736-5.9627-15.5746-5.8415-6.201-12.6428-9.2818-15.1912-6.8812-2.5483 2.4006.1213 9.3736 5.9628 15.5746 5.8415 6.201 12.6428 9.2818 15.1911 6.8812Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M53.3666 27.4034c2.5484-2.4007-.1212-9.3736-5.9627-15.5746-5.8415-6.201-12.6428-9.2818-15.1912-6.8812-2.5483 2.4006.1213 9.3736 5.9628 15.5746 5.8415 6.201 12.6428 9.2818 15.1911 6.8812Z"
    />
    <path
      fill="#fff"
      d="M53.484 27.4363c-2.5357 2.43-9.403-.6339-15.2138-6.8673-5.8108-6.2334-8.5577-13.2064-6.0221-15.6364l-5.3882 5.0713c-2.5356 2.4299.1057 9.4029 6.0221 15.6363 5.9165 6.2334 12.6781 9.2973 15.2138 6.8673l5.3882-5.0712Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M53.484 27.4363c-2.5357 2.43-9.403-.6339-15.2138-6.8673-5.8108-6.2334-8.5577-13.2064-6.0221-15.6364l-5.3882 5.0713c-2.5356 2.4299.1057 9.4029 6.0221 15.6363 5.9165 6.2334 12.6781 9.2973 15.2138 6.8673l5.3882-5.0712Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M53.484 27.4363c-2.5357 2.43-9.403-.6339-15.2138-6.8673-5.8108-6.2334-8.5577-13.2064-6.0221-15.6364l-5.3882 5.0713c-2.5356 2.4299.1057 9.4029 6.0221 15.6363 5.9165 6.2334 12.6781 9.2973 15.2138 6.8673l5.3882-5.0712Z"
    />
  </svg>
);

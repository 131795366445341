import type * as React from 'react';
export const NewAccountantIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={141}
    height={85}
    fill="none"
    viewBox="0 0 141 85"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M125 43h13M4 43h13M119.236 15.2247 130.21 9M22.1621 15.2247 11.1882 9M118.353 69.94l10.974 6.2247M23.9285 70.8232l-10.9739 6.2247"
    />
    <circle cx={72} cy={43} r={39} fill="#F8F8FB" />
    <path
      fill="#fff"
      d="M70.881 53.0942c-11.9669 0-22.2675 7.134-26.881 17.381 7.0476 6.5753 16.7201 10.6325 27.393 10.6325 10.3071 0 19.6813-3.784 26.6593-9.9655-4.4606-10.6031-14.9466-18.048-27.1713-18.048Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M70.881 53.0942C58.9141 53.0942 48.6135 58.753 44 69c7.0476 6.5753 16.7201 12.1077 27.393 12.1077 10.3071 0 19.6813-3.784 26.6593-9.9655-4.4606-10.6031-14.9466-18.048-27.1713-18.048Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m60.127 61.7706.5637-10.3923c.0144-.2656.3003-.423.534-.294l9.594 5.2947c.2563.1414.2518.5112-.0078.6415l-10.1576 5.0976c-.2486.1248-.5414-.0685-.5263-.3475Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m79.9575 62.9364.5679-10.3921c.0145-.2656-.2524-.4582-.4985-.3597l-10.1023 4.0418c-.2699.108-.3055.4757-.0619.638l9.5344 6.3503c.2334.1555.5451.0006.5604-.2783Z"
    />
    <path
      fill="#FED491"
      d="M71.4578 56.9527c10.0991 0 18.286-8.1868 18.286-18.2859 0-10.099-8.1869-18.2859-18.286-18.2859-10.099 0-18.2859 8.1869-18.2859 18.2859 0 10.0991 8.1869 18.2859 18.2859 18.2859Z"
    />
    <path
      fill="#FFB938"
      d="M59.35 45.8982c1.1678.3346 2.3857-.3408 2.7203-1.5086s-.3408-2.3857-1.5086-2.7203-2.3857.3408-2.7203 1.5086.3409 2.3857 1.5086 2.7203ZM76.4697 51.4138c1.1677.3346 2.3856-.3408 2.7202-1.5086s-.3408-2.3857-1.5086-2.7203c-1.1677-.3346-2.3856.3409-2.7202 1.5086-.3346 1.1678.3408 2.3857 1.5086 2.7203Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M63.6618 47.5122c1.6661 2.8994 5.3671 3.8992 8.2665 2.2332M77.4921 45.2806c.5399-1.5505-.2795-3.2452-1.83-3.785-1.5506-.5399-3.2453.2795-3.7851 1.83M67.3979 41.8041c.5398-1.5506-.2795-3.2452-1.8301-3.7851-1.5506-.5399-3.2452.2795-3.7851 1.8301"
    />
    <path
      fill="#1D1D1D"
      fillOpacity={0.66}
      d="M66.608 32.6311c-6.9265-2.5468-9.7317-2.4338-10.1314-4.4583 2.4004-1.9829 10.0974-9.4009 17.467-7.4852 3.1979.8314 10.8664 5.1086 12.7834 9.5117 6.4565 10.6992-1.7725 22.4062-1.1344 19.4467.8332-3.8642 2.312-5.784-.2111-9.8515-1.7775-2.8654-8.4422-5.0928-13.244-6.284l-.7323-3.7757c-.9772-.1509-3.8016 2.9778-4.7972 2.8963Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m56.7539 28.4385 10.9024 3.6085 3.4106-2.2042.9677 3.4897 2.583.8449c6.6202 2.0461 14.1904 5.8857 12.0953 13.4457l-.4982 1.8569"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M71.4578 56.9527c10.0991 0 18.286-8.1868 18.286-18.2859 0-10.099-8.1869-18.2859-18.286-18.2859-10.099 0-18.2859 8.1869-18.2859 18.2859 0 10.0991 8.1869 18.2859 18.2859 18.2859Z"
    />
    <circle cx={72} cy={43} r={38.5} stroke="#1D1D1D" strokeWidth={3} />
  </svg>
);

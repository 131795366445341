import type * as React from 'react';
export const ForbiddenIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={149}
    height={191}
    fill="none"
    viewBox="0 0 149 191"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path stroke="#000" strokeWidth={3} d="M116.747 60.325v123.178" />
    <path
      fill="#fff"
      d="M103.571 126.931c10.629-.497 20.364 6.556 18.775 12.516-1.391 5.563-9.934 8.444-22.152.596"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M103.571 126.931c10.629-.497 20.364 6.556 18.775 12.516-1.391 5.563-9.934 8.444-22.152.596"
    />
    <path
      fill="#fff"
      d="M40.7895 77.7579c.7947-1.9868 2.4835-4.0728 1.6887-6.3576-1.6887-5.0662-10.3311-11.6225-14.5033-9.9338-4.1722 1.6888-5.9602 11.2252-4.6688 16.4901.596 2.5828 4.1721 3.8741 6.4569 4.8675"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M40.7895 77.7579c.7947-1.9868 2.4835-4.0728 1.6887-6.3576-1.6887-5.0662-10.3311-11.6225-14.5033-9.9338-4.1722 1.6888-5.9602 11.2252-4.6688 16.4901.596 2.5828 4.1721 3.8741 6.4569 4.8675"
    />
    <path
      fill="#fff"
      d="M81.1207 75.4744c-.7947-1.9868-2.4834-4.0729-1.6887-6.3576 1.6887-5.0662 10.3311-11.6225 14.5033-9.9338 4.1722 1.6887 5.9603 11.2252 4.6689 16.4901-.5961 2.5827-4.1722 3.8741-6.457 4.8675"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M81.1207 75.4744c-.7947-1.9868-2.4834-4.0729-1.6887-6.3576 1.6887-5.0662 10.3311-11.6225 14.5033-9.9338 4.1722 1.6887 5.9603 11.2252 4.6689 16.4901-.5961 2.5827-4.1722 3.8741-6.457 4.8675"
    />
    <path
      fill="#000"
      d="M54.8956 189.215c27.3765 0 49.5694-2.847 49.5694-6.358 0-3.511-22.1929-6.358-49.5694-6.358s-49.5695 2.847-49.5695 6.358c0 3.511 22.193 6.358 49.5695 6.358Z"
    />
    <path
      fill="#000"
      d="M116.24 187.477c16.459 0 29.802-1.557 29.802-3.477s-13.343-3.477-29.802-3.477c-16.4584 0-29.8009 1.557-29.8009 3.477s13.3425 3.477 29.8009 3.477Z"
    />
    <path
      fill="#fff"
      d="M110.823 125.739c-3.179-35.1652-21.7554-57.0195-47.5832-59.1056-39.3377-3.1788-48.8742 33.9736-56.8212 56.4236-9.437 26.225 5.2649 44.106 23.7418 52.55.6953 4.371 3.0794 7.053 6.2582 7.55 2.4835.397 4.9669-.696 6.8543-3.179 3.9735.894 7.9471 1.291 11.7219 1.291 4.1722 0 8.245-.199 12.1192-.795 1.7881 2.087 4.0728 2.981 6.3576 2.683 2.5828-.398 4.5695-2.285 5.6623-5.265 20.9601-6.755 34.2711-23.444 31.6891-52.153Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M110.823 125.739c-3.179-35.1652-21.7554-57.0195-47.5832-59.1056-39.3377-3.1788-48.8742 33.9736-56.8212 56.4236-9.437 26.225 5.2649 44.106 23.7418 52.55.6953 4.371 3.0794 7.053 6.2582 7.55 2.4835.397 4.9669-.696 6.8543-3.179 3.9735.894 7.9471 1.291 11.7219 1.291 4.1722 0 8.245-.199 12.1192-.795 1.7881 2.087 4.0728 2.981 6.3576 2.683 2.5828-.398 4.5695-2.285 5.6623-5.265 20.9601-6.755 34.2711-23.444 31.6891-52.153Z"
    />
    <path
      fill="#000"
      d="M90.1603 90.1746c1.2619 0 2.2848-1.0229 2.2848-2.2847 0-1.2619-1.0229-2.2848-2.2848-2.2848-1.2618 0-2.2848 1.0229-2.2848 2.2848 0 1.2618 1.023 2.2847 2.2848 2.2847ZM46.4518 90.1746c1.2618 0 2.2847-1.0229 2.2847-2.2847 0-1.2619-1.0229-2.2848-2.2847-2.2848-1.2619 0-2.2848 1.0229-2.2848 2.2848 0 1.2618 1.0229 2.2847 2.2848 2.2847Z"
    />
    <path
      fill="#fff"
      d="M29.7629 129.117c.9934 10.629-5.5629 20.662-11.6225 19.371-5.5629-1.193-8.9404-9.537-1.6887-22.053"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M29.7629 129.117c.9934 10.629-5.5629 20.662-11.6225 19.371-5.5629-1.193-8.9404-9.537-1.6887-22.053"
    />
    <path
      fill="#000"
      d="M73.571 101.798c2.7814 0 5.0662 0 5.0662-2.2843 0-2.1855-2.2848-2.2848-5.0662-2.2848h-8.4437c-2.7815 0-5.0663 0-5.0663 2.2848 0 2.2843 2.2848 2.2843 5.0663 2.2843h8.4437Z"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.352}
      d="M43.8211 98.1898c.5529-2.1803-1.5424-4.5928-4.6799-5.3884-3.1375-.7956-6.1292.3269-6.6821 2.5072-.5529 2.1803 1.5424 4.5928 4.6799 5.3884 3.1375.796 6.1292-.327 6.6821-2.5072ZM99.7689 101.103c2.9121-1.4124 4.4781-4.198 3.4971-6.222-.982-2.024-4.1385-2.5199-7.0511-1.1076-2.9125 1.4122-4.478 4.1979-3.4966 6.2219.9814 2.0237 4.1381 2.5197 7.0506 1.1077Z"
    />
    <circle cx={117.234} cy={31.517} r={28.301} fill="#fff" />
    <circle
      cx={117.234}
      cy={31.517}
      r={28.301}
      fill="#F46147"
      fillOpacity={0.7}
    />
    <circle
      cx={117.234}
      cy={31.517}
      r={28.301}
      stroke="#1D1D1D"
      strokeWidth={3}
    />
    <rect
      width={43.689}
      height={13.887}
      x={95.886}
      y={25.07}
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      rx={3.467}
    />
  </svg>
);

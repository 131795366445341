import type * as React from 'react';
export const UnicornSupercleanIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={200}
    height={134}
    fill="none"
    viewBox="0 0 200 134"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M98.6584 107.723c-9.4907 8.486-16.525 10.942-22.3311 5.918-3.9079-3.462-.6699-11.166 3.908-15.0738"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M98.6584 107.723c-9.4907 8.486-16.525 10.942-22.3311 5.918-3.9079-3.462-.6699-11.166 3.908-15.0738"
    />
    <path
      fill="#fff"
      d="M147.675 100.019c.67.893 1.452 1.786 2.345 2.568 4.578 4.02 10.496 4.801 13.176 1.786 2.679-3.014 1.004-8.8203-3.573-12.8399-.894-.7816-1.899-1.4515-2.792-2.0098"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M147.675 100.019c.67.893 1.452 1.786 2.345 2.568 4.578 4.02 10.496 4.801 13.176 1.786 2.679-3.014 1.004-8.8203-3.573-12.8399-.894-.7816-1.899-1.4515-2.792-2.0098"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M136.51 106.271c-17.864 8.374-53.8175 16.079-68.7794-20.5444-4.6895-16.9716 5.0245-46.0021 43.2104-53.9296 27.356-5.5828 47.007 10.8306 51.697 27.8022 4.578 16.86-6.476 37.4046-26.128 46.6718Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M126.684 59.2646c0-2.2331-1.452-4.3546-3.796-4.9129-2.68-.6699-5.472.8933-6.142 3.6847l-.334 1.4515"
    />
    <path
      fill="#fff"
      d="M121.767 65.7858c.652-2.5703-1.775-5.4034-5.421-6.328-3.647-.9246-7.131.4095-7.782 2.9797-.652 2.5702 1.775 5.4033 5.421 6.3279 3.646.9246 7.131-.4094 7.782-2.9796Z"
    />
    <path
      fill="#F46147"
      fillOpacity={0.5}
      d="M121.767 65.7858c.652-2.5703-1.775-5.4034-5.421-6.328-3.647-.9246-7.131.4095-7.782 2.9797-.652 2.5702 1.775 5.4033 5.421 6.3279 3.646.9246 7.131-.4094 7.782-2.9796Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M121.767 65.7858c.652-2.5703-1.775-5.4034-5.421-6.328-3.647-.9246-7.131.4095-7.782 2.9797-.652 2.5702 1.775 5.4033 5.421 6.3279 3.646.9246 7.131-.4094 7.782-2.9796ZM143.767 63.6191c1.117-2.0098 3.35-3.1263 5.695-2.4564 2.679.6699 4.354 3.4613 3.684 6.141l-.335 1.4516"
    />
    <path
      fill="#fff"
      d="M157.951 74.7657c.622-2.4506-1.733-5.1623-5.26-6.0566-3.526-.8943-6.889.3675-7.511 2.8181-.621 2.4507 1.734 5.1623 5.261 6.0566 3.526.8943 6.889-.3674 7.51-2.8181Z"
    />
    <path
      fill="#F46147"
      fillOpacity={0.5}
      d="M157.951 74.7657c.622-2.4506-1.733-5.1623-5.26-6.0566-3.526-.8943-6.889.3675-7.511 2.8181-.621 2.4507 1.734 5.1623 5.261 6.0566 3.526.8943 6.889-.3674 7.51-2.8181Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M157.951 74.7657c.622-2.4506-1.733-5.1623-5.26-6.0566-3.526-.8943-6.889.3675-7.511 2.8181-.621 2.4507 1.734 5.1623 5.261 6.0566 3.526.8943 6.889-.3674 7.51-2.8181Z"
    />
    <path
      fill="#fff"
      d="M73.202 77.911c-9.6023 2.1215-15.5201 6.2528-14.0686 12.7288 1.1166 5.1361 9.4908 7.4809 15.5202 2.6797"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M73.202 77.911c-9.6023 2.1215-15.5201 6.2528-14.0686 12.7288 1.1166 5.1361 9.4908 7.4809 15.5202 2.6797"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.347}
      d="M91.6241 74.8965c-5.1361 8.3742-4.8011 15.1852 1.4516 17.9766 4.6895 2.2331 12.7283-3.0147 14.7383-7.3693"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m34.2354 103.699-30.0353 8.375M53.6648 113.525l-24.1176 10.161"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.347}
      d="M93.9361 84.843c-1.947 1.947-2.2065 4.5593-1.0213 8.0301"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M138.185 67.8619c-.67 2.2332-3.015 3.4614-5.248 2.7914-2.233-.6699-3.461-3.0147-2.792-5.2478"
    />
    <path
      fill="#D6CEF9"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M136.623 51.5603c-3.573-3.1264-3.573-8.7092 0-11.9472l37.516-33.9433c.893-.8932 2.345-.8932 3.35 0 .893.8933 1.116 2.2331.335 3.3497l-29.366 41.2009c-2.679 3.908-8.262 4.5779-11.835 1.3399Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.902}
      d="M134.838 42.0511c3.24 3.6719 11.879 3.7799 19.223-.3239"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.972}
      d="M143.77 33.2692c3.097 3.5396 10.84 2.4335 17.809-1.4379"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.919}
      d="M155.717 22.4895c2.39 2.716 6.301 3.4764 11.407 1.3037"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.866}
      d="M166.77 12.591c1.494 1.7068 4.268 3.2003 7.041 1.8135"
    />
  </svg>
);

import type * as React from 'react';
export const HeroUserIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={163}
    height={83}
    fill="none"
    viewBox="0 0 163 83"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M145.513 41.2423h14.285M3 41.2423h14.285M139 10.048 151.425 3M23.4253 10.048 11 3M138 72l12.425 7.0479M25.4253 73 13 80.0479"
    />
    <path
      fill="#fff"
      d="m103.402 55.1361-8.4019 4.8012 6.8019 13.07 1.389.7424a36.4097 36.4097 0 0 0 9.294-7.9171l-.947-.0271c-.667 0-1.201-.5335-1.067-1.2003l.533-3.4676c.134-.6668-.533-1.3337-1.2-1.2003l-3.868.6668c-.667.1334-1.2-.4001-1.2-1.0669l.267-3.4676c0-.8002-.8-1.3336-1.601-.9335Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.25}
      d="m103.402 55.1361-8.4019 4.8012 6.8019 13.07 1.389.7424a36.4097 36.4097 0 0 0 9.294-7.9171l-.947-.0271c-.667 0-1.201-.5335-1.067-1.2003l.533-3.4676c.134-.6668-.533-1.3337-1.2-1.2003l-3.868.6668c-.667.1334-1.2-.4001-1.2-1.0669l.267-3.4676c0-.8002-.8-1.3336-1.601-.9335Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.362}
      d="m103.191 73.7497-1.389-.7424-6.8019-13.07 8.4019-4.8012c.801-.4001 1.601.1333 1.601.9335l-.267 3.4676c0 .6668.533 1.2003 1.2 1.0669l3.868-.6668c.667-.1334 1.334.5335 1.2 1.2003l-.533 3.4676c-.134.6668.4 1.2003 1.067 1.2003l.947.0271"
    />
    <path
      fill="#fff"
      d="M82.3257 60c-9.8958 0-18.5586 4.2726-23.3256 10.6628 6.3491 5.4865 14.6238 8.805 23.6736 8.805 8.8471 0 16.9535-3.1716 23.2443-8.4397C101.212 64.4359 92.4089 60 82.3257 60Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.5}
      d="M82.3257 60c-9.8958 0-18.5586 4.2726-23.3256 10.6628 6.3491 5.4865 14.6238 8.805 23.6736 8.805 8.8471 0 16.9535-3.1716 23.2443-8.4397C101.212 64.4359 92.4089 60 82.3257 60Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.362}
      d="M59.0001 70.6628C63.7671 64.2726 72.4299 60 82.3257 60c10.0832 0 18.8863 4.4359 23.5923 11.0281"
    />
    <path
      fill="#DFB0ED"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.362}
      d="M82.2492 69.4578c13.0577 0 23.6428-10.4751 23.6428-23.3968S95.3069 22.6642 82.2492 22.6642 58.6061 33.1393 58.6061 46.061s10.5854 23.3968 23.6431 23.3968Z"
    />
    <path
      fill="#DFB0ED"
      d="M63.531 35.2224s-4.4331-7.7579-2.2165-13.2993c0 0 8.743-2.0933 12.9298 6.034"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.362}
      d="M63.531 35.2224s-4.4331-7.7579-2.2165-13.2993c0 0 8.743-2.0933 12.9298 6.034"
    />
    <path
      fill="#DFB0ED"
      d="M99.9808 35.2224s4.4332-7.7579 2.2162-13.2993c0 0-8.7426-2.0933-12.9294 6.034"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.362}
      d="M99.9808 35.2224s4.4332-7.7579 2.2162-13.2993c0 0-8.7426-2.0933-12.9294 6.034"
    />
    <path
      fill="#FFD58C"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.362}
      d="M82.2483 65.3509c7.6183 0 13.7941-6.6215 13.7941-14.7895s-6.1758-14.7895-13.7941-14.7895c-7.6182 0-13.794 6.6215-13.794 14.7895s6.1758 14.7895 13.794 14.7895Z"
    />
    <path
      fill="#fff"
      d="M86.8025 33.2489c.8841 0 1.6008-.7167 1.6008-1.6008s-.7167-1.6009-1.6008-1.6009-1.6008.7168-1.6008 1.6009.7167 1.6008 1.6008 1.6008Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.7}
      d="M86.8025 33.2489c.8841 0 1.6008-.7167 1.6008-1.6008s-.7167-1.6009-1.6008-1.6009-1.6008.7168-1.6008 1.6009.7167 1.6008 1.6008 1.6008Z"
    />
    <path
      fill="#fff"
      d="M76.524 34.6558c.7657-.442 1.028-1.4211.5859-2.1867-.442-.7657-1.4211-1.028-2.1867-.5859-.7657.442-1.028 1.4211-.5859 2.1867.442.7657 1.4211 1.028 2.1867.5859Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.7}
      d="M76.524 34.6558c.7657-.442 1.028-1.4211.5859-2.1867-.442-.7657-1.4211-1.028-2.1867-.5859-.7657.442-1.028 1.4211-.5859 2.1867.442.7657 1.4211 1.028 2.1867.5859Z"
    />
    <path
      fill="#fff"
      d="M82.0603 29.249c.7656-.442 1.0279-1.4211.5859-2.1867-.4421-.7657-1.4211-1.028-2.1868-.5859-.7656.442-1.0279 1.4211-.5859 2.1867.4421.7657 1.4211 1.028 2.1868.5859Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.7}
      d="M82.0603 29.249c.7656-.442 1.0279-1.4211.5859-2.1867-.4421-.7657-1.4211-1.028-2.1868-.5859-.7656.442-1.0279 1.4211-.5859 2.1867.4421.7657 1.4211 1.028 2.1868.5859Z"
    />
    <path
      fill="#1D1D1D"
      d="M78.226 56c-.556 0-1.0149.4579-.9051 1.003.4894 2.4278 2.6344 4.256 5.2064 4.256 2.572 0 4.7171-1.8282 5.2064-4.256.1099-.5451-.349-1.003-.9051-1.003H78.226Z"
    />
    <circle cx={82.25} cy={41.25} r={38.75} stroke="#1D1D1D" strokeWidth={3} />
    <path
      fill="#1D1D1D"
      d="M76.0236 52.201c-1.1176-.0701-1.9524-1.2628-1.8645-2.6639.0879-1.4012 1.0651-2.4803 2.1827-2.4102 1.1176.0701 1.9524 1.2628 1.8645 2.664-.0879 1.4012-1.0651 2.4802-2.1827 2.4101ZM88.0236 52.201c-1.1176-.0701-1.9524-1.2628-1.8645-2.6639.0879-1.4012 1.0651-2.4803 2.1827-2.4102 1.1176.0701 1.9524 1.2628 1.8645 2.664-.0879 1.4012-1.0651 2.4802-2.1827 2.4101Z"
    />
    <path
      fill="#FFB938"
      d="M73.5 53c1.3807 0 2.5 1.1193 2.5 2.5S74.8807 58 73.5 58 71 56.8807 71 55.5s1.1193-2.5 2.5-2.5ZM90.5 53c1.3807 0 2.5 1.1193 2.5 2.5S91.8807 58 90.5 58 88 56.8807 88 55.5s1.1193-2.5 2.5-2.5Z"
    />
  </svg>
);

import type * as React from 'react';
export const MicroGlowIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#80DBC1"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M37.8692 35.4664C38.726 34.2845 42 33.8249 42 33.8249s-2.4007-.3914-3.8248-1.6415c-1.6829-1.4774-1.6829-5.417-1.6829-5.417s-.459 3.7755-1.5299 5.0887c-1.071 1.3132-4.5898 1.6415-4.5898 1.6415s3.6106.394 4.5898 1.9698c.9791 1.5759 1.5299 5.2528 1.5299 5.2528s.5202-4.0709 1.3769-5.2528ZM31.7996 14.3376c.8568-1.1326 4.1308-1.5731 4.1308-1.5731s-2.4007-.3751-3.8248-1.5731c-1.6829-1.4158-1.6829-5.1913-1.6829-5.1913s-.459 3.6182-1.5299 4.8767c-1.0709 1.2585-4.5897 1.5731-4.5897 1.5731s3.6106.3775 4.5897 1.8877c.9792 1.5102 1.5299 5.034 1.5299 5.034s.5202-3.9013 1.3769-5.034ZM19.0156 31.325c1.4875-2.43 7.1719-3.375 7.1719-3.375s-4.1682-.8047-6.6406-3.375c-2.9219-3.0375-2.9219-11.1375-2.9219-11.1375S15.8281 21.2 13.9688 23.9C12.1094 26.6 6 27.275 6 27.275s6.2687.81 7.9687 4.05c1.7 3.24 2.6563 10.8 2.6563 10.8s.9031-8.37 2.3906-10.8Z"
    />
  </svg>
);

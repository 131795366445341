import type * as React from 'react';
export const MicroCardletterIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M4.9998 26.2453c0-.6272.3311-1.2077.871-1.5269L23.097 14.5337a1.7738 1.7738 0 0 1 1.8055 0l17.2262 10.1847c.5399.3192.8711.8997.8711 1.5269v16.9809c0 .9796-.7942 1.7738-1.7739 1.7738H6.7736c-.9797 0-1.7738-.7942-1.7738-1.7738V26.2453Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M10.9998 7.1303C10.9998 5.4015 12.4012 4 14.13 4h19.7395c1.7288 0 3.1303 1.4015 3.1303 3.1303v26.7394c0 1.7288-1.4015 3.1303-3.1303 3.1303H14.13c-1.7288 0-3.1302-1.4015-3.1302-3.1303V7.1303Z"
    />
    <mask id="MicroCardletterIllustration__a" fill="#fff">
      <rect width={7} height={8} x={25.8} y={8.325} rx={1.043} />
    </mask>
    <rect
      width={7}
      height={8}
      x={25.8}
      y={8.325}
      stroke="#1D1D1D"
      strokeWidth={4}
      mask="url(#MicroCardletterIllustration__a)"
      rx={1.043}
    />
    <path
      fill="#fff"
      d="m24.1247 36.4829 16.1875-7.3203-.7017 12.9625H8.2872l.0005-12.9625 15.837 7.3203Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="m4.9998 27 19 10 19-10M40 45l-16-8"
    />
  </svg>
);

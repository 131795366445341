import type * as React from 'react';
export const OhNoIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={121}
    height={145}
    fill="none"
    viewBox="0 0 121 145"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#000"
      d="M74.5 144c25.129 0 45.5-2.91 45.5-6.5S99.629 131 74.5 131 29 133.91 29 137.5s20.371 6.5 45.5 6.5Z"
    />
    <path
      fill="#BFEDE0"
      stroke="#000"
      strokeWidth={3}
      d="M22.8407 9.6818c4.3248.9245 9.33 2.553 11.1157 4.9975l-13.0406 9.4195c-3.2025-1.9284-3.9146-6.3751-3.741-10.2659.1285-2.879 2.8477-4.7535 5.6659-4.151ZM99.9424 9.6818c-4.3248.9245-9.33 2.553-11.1157 4.9975l13.0403 9.4195c3.203-1.9284 3.915-6.3751 3.741-10.2659-.128-2.879-2.847-4.7535-5.6656-4.151Z"
    />
    <path
      fill="#BFEDE0"
      stroke="#000"
      strokeWidth={3}
      d="M104.028 71.3827c5.168 5.6136 11.281 13.8183 10.336 26.7728-1.348 18.4705-10.287 29.2805-20.6717 34.9595-9.9057 5.418-19.6154 8.077-32.3077 8.077-12.6923 0-28.8503-5.07-42.6318-21.014C1.9563 100.746-1.0044 75.2142 6.2631 48.4963 12.7233 24.7462 28.2278 7.9052 55.3607 4.0188 82.4936.1324 96.6432 12.9617 104.028 23.4507c11.552 16.4092 2.01 40.4471 0 47.932Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={3}
      d="M99.1154 46.5769c0 13.9115-13.5361 25.6154-30.8077 25.6154S37.5 60.4884 37.5 46.5769s13.5361-25.6154 30.8077-25.6154 30.8077 11.7039 30.8077 25.6154Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m54.658 52.0196 5.3691-3.8717-5.1411-4.1422M87.0432 44.6944l-5.3366 3.9868 5.141 4.1421"
    />
    <path
      fill="#BFEDE0"
      d="M97.9106 74.0052c-.0623-7.345-5.998-13.0626-10.7473-11.2484-4.2764 1.6914-6.2696 7.8608.503 15.1843"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M97.9106 74.0052c-.0623-7.345-5.998-13.0626-10.7473-11.2484-4.2764 1.6914-6.2696 7.8608.503 15.1843"
    />
    <path
      fill="#BFEDE0"
      d="M53.6513 78.7344c6.6198-2.8581 8.7572-10.6879 4.6815-14.5182-3.7222-3.4268-10.3303-2.9737-13.4934 6.2648"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M53.6513 78.7344c6.6198-2.8581 8.7572-10.6879 4.6815-14.5182-3.7222-3.4268-10.3303-2.9737-13.4934 6.2648"
    />
    <path
      fill="#BFEDE0"
      d="M62.4011 123.268c6.5224-4.714 8.6282-13.472 4.6123-16.425-3.6676-2.623-10.1786-.431-13.295 10.069"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M62.4011 123.268c6.5224-4.714 8.6282-13.472 4.6123-16.425-3.6676-2.623-10.1786-.431-13.295 10.069M103.5 110.038c-3.462-6.923-7.6777-10.1189-12.1558-7.929-4.0236 2.035-5.0987 8.82 2.7612 16.447"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3.479}
      d="M68.1788 57.4406c1.68-1.1214 4.0232-.9836 5.586.322"
    />
    <path
      fill="#fff"
      d="m43.7434 36.7476-4.7965-11.5167-4.0831 11.8315c-1.0288 2.9812 1.0696 6.1063 4.2134 6.2751 3.5032.1881 6.0129-3.3562 4.6662-6.5899Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="m43.7434 36.7476-4.7965-11.5167-4.0831 11.8315c-1.0288 2.9812 1.0696 6.1063 4.2134 6.2751 3.5032.1881 6.0129-3.3562 4.6662-6.5899Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={3}
      d="m43.7434 36.7476-4.7965-11.5167-4.0831 11.8315c-1.0288 2.9812 1.0696 6.1063 4.2134 6.2751 3.5032.1881 6.0129-3.3562 4.6662-6.5899Z"
    />
  </svg>
);

import type * as React from 'react';
export const PapersIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={75}
    height={92}
    fill="none"
    viewBox="0 0 75 92"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M46.567 12.4075 4.0672 20.7852c-1.1417.2251-1.8848 1.3331-1.6597 2.4748l11.0246 55.9266c.225 1.1417 1.333 1.8848 2.4747 1.6597l42.4997-8.3778c1.1417-.225 1.8848-1.333 1.6598-2.4747L49.0417 14.0672c-.2251-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m55.567 20.4075-42.4998 8.3777c-1.1417.2251-1.8848 1.3331-1.6597 2.4748l11.0246 55.9266c.225 1.1417 1.333 1.8848 2.4747 1.6597l42.4997-8.3778c1.1417-.225 1.8848-1.333 1.6598-2.4747L58.0417 22.0672c-.2251-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m59.0205 2.7698-42.4998 8.3778c-1.1417.225-1.8848 1.333-1.6597 2.4747l11.0246 55.9266c.225 1.1417 1.333 1.8848 2.4747 1.6597L70.86 62.8308c1.1417-.225 1.8848-1.333 1.6597-2.4747L61.4952 4.4295c-.2251-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m29.7274 28.446 23.2857-4.6053M31.2408 37l23.3073-4.6052M33.1828 45.5327l23.3074-4.5836"
    />
  </svg>
);

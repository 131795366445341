import type * as React from 'react';
export const EmptyChestIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={102}
    height={79}
    fill="none"
    viewBox="0 0 102 79"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M89.6695 63.0101H3.0519v8.841h86.6176v-8.841Z"
    />
    <path fill="#fff" d="m77.5397 28.5001-62.36.0001v34.0655h62.36V28.5001Z" />
    <path
      fill="#B039D3"
      fillOpacity={0.5}
      d="m77.5397 28.5001-62.36.0001v34.0655h62.36V28.5001Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="m77.5397 28.5001-62.36.0001v34.0655h62.36V28.5001Z"
    />
    <path
      fill="#FDC846"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M38.4557 52.3727c-4.3609 0-7.9723-3.4647-7.9723-7.7954v-15.724h15.8765v15.7906c0 4.2641-3.5432 7.7288-7.9042 7.7288Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M38.4208 45.0716v-5.3249M38.4221 39.7469c1.4704 0 2.6624-1.1921 2.6624-2.6625 0-1.4704-1.192-2.6624-2.6624-2.6624s-2.6625 1.192-2.6625 2.6624 1.1921 2.6625 2.6625 2.6625Z"
    />
    <path
      fill="#fff"
      d="M88.0505 7.501c5.7724 0 10.4485 4.6867 10.4485 10.499 0 5.8123-4.6761 10.499-10.4485 10.499H78.501V7.501h9.5495Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.5}
      d="M88.0505 7.501c5.7724 0 10.4485 4.6867 10.4485 10.499 0 5.8123-4.6761 10.499-10.4485 10.499H78.501V7.501h9.5495Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M88.0505 7.501c5.7724 0 10.4485 4.6867 10.4485 10.499 0 5.8123-4.6761 10.499-10.4485 10.499H78.501V7.501h9.5495Z"
    />
    <path stroke="#1D1D1D" strokeWidth={3} d="M57.5 29v33" />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M33.5 7.5h45v21h-45z"
    />
  </svg>
);

import type * as React from 'react';
export const MicroPapersIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path fill="#fff" stroke="#1D1D1D" strokeWidth={2} d="M7 13h20v28H7z" />
    <path stroke="#1D1D1D" strokeWidth={2} d="M11 22h12.773M11 26h6.723" />
    <path
      fill="#fff"
      d="M38.1405 7v26.2183H21.3569c-3.9162-1.0084-3.3567-5.5462-3.3567-5.5462V6.9999h20.1403Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M36.9726 7v26h-16C17.4725 33 17 30.5 17 27.5V7h19.9726Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M42.9725 25h-18v4a4 4 0 0 1-4 4h18a4 4 0 0 0 4-4v-4Z"
    />
    <path stroke="#1D1D1D" strokeWidth={2} d="M21 12h12.773M21 16h6.723" />
  </svg>
);

import type * as React from 'react';
export const AirheadIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={119}
    height={127}
    fill="none"
    viewBox="0 0 119 127"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M24.0793 72.5726c-1.806 9.8709 3.4066 38.0004 26.0988 46.1004 6.8174 2.433 14.3915 1.858 21.0501-.45m0 0c12.1015-4.195 21.179-14.113 17.1949-22.0926-6.1764-12.3695-32.1591-4.1354-22.7859 15.0586 1.4049 2.876 3.3188 5.199 5.591 7.034Zm0 0c12.8876 10.407 37.2998 5.128 45.7168-3.644"
    />
    <path
      fill="#fff"
      d="M16.4139 81.0624c-.9457-.9701.5134-5.0911 1.2722-6.1583l12.2625.2725s2.7459 6.0148.9863 6.9207c-1.7596.906-2.0601-.8512-3.5791-.9649-1.519-.1138-1.7422 2.8671-3.2612 2.7533-1.519-.1137-2.3084-3.1704-3.8274-3.2841-1.519-.1138-2.6712 1.6734-3.8533.4608Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="M16.4139 81.0624c-.9457-.9701.5134-5.0911 1.2722-6.1583l12.2625.2725s2.7459 6.0148.9863 6.9207c-1.7596.906-2.0601-.8512-3.5791-.9649-1.519-.1138-1.7422 2.8671-3.2612 2.7533-1.519-.1137-2.3084-3.1704-3.8274-3.2841-1.519-.1138-2.6712 1.6734-3.8533.4608Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M16.4139 81.0624c-.9457-.9701.5134-5.0911 1.2722-6.1583l12.2625.2725s2.7459 6.0148.9863 6.9207c-1.7596.906-2.0601-.8512-3.5791-.9649-1.519-.1138-1.7422 2.8671-3.2612 2.7533-1.519-.1137-2.3084-3.1704-3.8274-3.2841-1.519-.1138-2.6712 1.6734-3.8533.4608Z"
    />
    <path
      fill="#fff"
      d="M58.5566 36.9326c-1.879 9.0959-7.2536 19.8263-14.1251 27.8543-6.9526 8.1228-14.977 12.9749-22.1314 11.4969-3.5693-.7373-6.7875-3.0064-9.5741-6.4426-2.7871-3.437-5.0777-7.9668-6.7816-13.0366C2.53 46.6452 1.5696 34.6171 3.4432 25.5472c1.9625-9.4997 6.6626-15.8073 12.6715-19.3795 6.0346-3.5873 13.5491-4.5098 21.272-2.9144C45.068 4.8401 51.2551 8.8213 55.072 14.557c3.8118 5.7282 5.3505 13.3434 3.4846 22.3756Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="M58.5566 36.9326c-1.879 9.0959-7.2536 19.8263-14.1251 27.8543-6.9526 8.1228-14.977 12.9749-22.1314 11.4969-3.5693-.7373-6.7875-3.0064-9.5741-6.4426-2.7871-3.437-5.0777-7.9668-6.7816-13.0366C2.53 46.6452 1.5696 34.6171 3.4432 25.5472c1.9625-9.4997 6.6626-15.8073 12.6715-19.3795 6.0346-3.5873 13.5491-4.5098 21.272-2.9144C45.068 4.8401 51.2551 8.8213 55.072 14.557c3.8118 5.7282 5.3505 13.3434 3.4846 22.3756Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M58.5566 36.9326c-1.879 9.0959-7.2536 19.8263-14.1251 27.8543-6.9526 8.1228-14.977 12.9749-22.1314 11.4969-3.5693-.7373-6.7875-3.0064-9.5741-6.4426-2.7871-3.437-5.0777-7.9668-6.7816-13.0366C2.53 46.6452 1.5696 34.6171 3.4432 25.5472c1.9625-9.4997 6.6626-15.8073 12.6715-19.3795 6.0346-3.5873 13.5491-4.5098 21.272-2.9144C45.068 4.8401 51.2551 8.8213 55.072 14.557c3.8118 5.7282 5.3505 13.3434 3.4846 22.3756Z"
    />
    <path
      fill="#1D1D1D"
      d="M15.7587 42.9089c.3414-1.9117-.1886-3.605-1.1839-3.782-.9953-.1771-2.0789 1.2291-2.4203 3.1408-.3415 1.9117.1886 3.605 1.1839 3.782.9953.1771 2.0789-1.2291 2.4203-3.1408ZM34.55 46.7831c.3414-1.9117-.1886-3.6049-1.1839-3.782-.9953-.177-2.0789 1.2292-2.4203 3.1409-.3415 1.9117.1886 3.6049 1.1839 3.782.9953.177 2.0789-1.2292 2.4203-3.1409Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M11.0053 21.3211c.8262-4.0009 3.1781-7.2933 6.3079-9.3852M25.7688 8.3647c-.9856.1687-2.5039.5067-3.8583 1.1678"
    />
    <path
      fill="#1D1D1D"
      d="M20.5311 62.0238c2.1195.4378 4.1925-.9255 4.6302-3.045.4377-2.1195-.9257-4.1927-3.0452-4.6306-2.1195-.4378-4.1925.9255-4.6302 3.045-.4377 2.1196.9257 4.1927 3.0452 4.6306Z"
    />
    <path
      fill="#1D1D1D"
      d="M22.0206 54.8114a3.4359 3.4359 0 0 1 2.6784 4.0727 3.4354 3.4354 0 0 1-4.0724 2.6781 3.436 3.436 0 0 1-2.6783-4.0727 3.4351 3.4351 0 0 1 4.0723-2.6781Zm.191-.9248c-2.3812-.4919-4.7011 1.0537-5.1881 3.4119-.487 2.3581 1.054 4.7013 3.4121 5.1884 2.3581.4872 4.7011-1.0537 5.1881-3.4118.487-2.3582-1.0309-4.6966-3.4121-5.1885Z"
    />
  </svg>
);

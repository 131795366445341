import type * as React from 'react';
export const PapersCheckIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={90}
    height={96}
    fill="none"
    viewBox="0 0 90 96"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M49.567 14.4075 7.0672 22.7852c-1.1417.2251-1.8848 1.3331-1.6597 2.4748l11.0246 55.9266c.225 1.1417 1.333 1.8848 2.4747 1.6597l42.4997-8.3778c1.1417-.225 1.8848-1.333 1.6598-2.4747L52.0417 16.0672c-.2251-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m58.567 22.4075-42.4998 8.3777c-1.1417.2251-1.8848 1.3331-1.6597 2.4748l11.0246 55.9266c.225 1.1417 1.333 1.8848 2.4747 1.6597l42.4997-8.3778c1.1417-.225 1.8848-1.333 1.6598-2.4747L61.0417 24.0672c-.2251-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m62.0205 4.7698-42.4998 8.3778c-1.1417.225-1.8848 1.333-1.6597 2.4747l11.0246 55.9266c.225 1.1417 1.333 1.8848 2.4747 1.6597L73.86 64.8308c1.1417-.225 1.8848-1.333 1.6597-2.4747L64.4952 6.4295c-.2251-1.1417-1.333-1.8848-2.4747-1.6597Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m32.7274 30.446 23.2858-4.6053M34.2407 39l23.3074-4.6052M36.1829 47.5327l23.3073-4.5836"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M82.893 48H63.107C61.9433 48 61 48.9433 61 50.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C84.0567 72 85 71.0567 85 69.893V50.107C85 48.9433 84.0567 48 82.893 48Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M68 59.6267 72.125 64 79 56"
    />
  </svg>
);

import type * as React from 'react';
export const SuccessMarkIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={64}
    height={70}
    fill="none"
    viewBox="0 0 64 70"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <rect width={55} height={3} x={5} y={63} fill="#1D1D1D" rx={1.5} />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m26.4288 38.9709-9.4853-9.8724c-1.4737-1.5338-3.8889-1.5338-5.3626 0-1.4412 1.5001-1.4412 3.9083 0 5.4083l12.3394 12.8428c.7292.759 1.7416 1.1829 2.8005 1.1485 1.0587-.0344 2.0412-.5228 2.7212-1.3248l23.1362-27.291c1.3431-1.5842 1.2057-3.9885-.3291-5.3981-1.5697-1.4415-3.9792-1.2765-5.3518.3426L26.4288 38.9709Z"
    />
  </svg>
);

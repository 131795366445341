import type * as React from 'react';
export const SecuritySweatIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={75}
    height={95}
    fill="none"
    viewBox="0 0 75 95"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M48.8095 52.7572V23.8249c0-6.009-4.8379-10.8995-10.8293-10.8995-5.9915 0-10.8294 4.8905-10.8294 10.8995v28.9323H16.8266V23.8249c0-11.7875 9.4813-21.3239 21.1536-21.3239 11.6722 0 21.1536 9.5364 21.1536 21.3239v28.9323H48.8095Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M48.8095 52.7572V23.8249c0-6.009-4.8379-10.8995-10.8293-10.8995-5.9915 0-10.8294 4.8905-10.8294 10.8995v28.9323H16.8266V23.8249c0-11.7875 9.4813-21.3239 21.1536-21.3239 11.6722 0 21.1536 9.5364 21.1536 21.3239v28.9323H48.8095Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={3.002}
      d="M48.8095 52.7572V23.8249c0-6.009-4.8379-10.8995-10.8293-10.8995-5.9915 0-10.8294 4.8905-10.8294 10.8995v28.9323H16.8266V23.8249c0-11.7875 9.4813-21.3239 21.1536-21.3239 11.6722 0 21.1536 9.5364 21.1536 21.3239v28.9323H48.8095Z"
    />
    <rect
      width={64.998}
      height={52.998}
      x={5.438}
      y={39.501}
      fill="#fff"
      rx={7.076}
    />
    <rect
      width={64.998}
      height={52.998}
      x={5.438}
      y={39.501}
      fill="#FFB938"
      fillOpacity={0.7}
      rx={7.076}
    />
    <rect
      width={64.998}
      height={52.998}
      x={5.438}
      y={39.501}
      stroke="#1D1D1D"
      strokeWidth={3.002}
      rx={7.076}
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.307 42.8056c.9474 0 1.7154.768 1.7154 1.7154v18.8221c0 .9474-.768 1.7154-1.7154 1.7154s-1.7155-.768-1.7155-1.7154V44.521c0-.9474.7681-1.7154 1.7155-1.7154ZM16.1833 43.8857c0-.9474.768-1.7154 1.7154-1.7154h3.4309c.9474 0 1.7154.768 1.7154 1.7154s-.768 1.7154-1.7154 1.7154h-3.4309c-.9474 0-1.7154-.768-1.7154-1.7154Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M14.0187 46.1583 7.714 31.02 2.347 46.5721c-1.3523 3.9186 1.4058 8.0264 5.5382 8.2483 4.6049.2472 7.9037-4.4116 6.1335-8.6621Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="M14.0187 46.1583 7.714 31.02 2.347 46.5721c-1.3523 3.9186 1.4058 8.0264 5.5382 8.2483 4.6049.2472 7.9037-4.4116 6.1335-8.6621Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={2.573}
      d="M14.0187 46.1583 7.714 31.02 2.347 46.5721c-1.3523 3.9186 1.4058 8.0264 5.5382 8.2483 4.6049.2472 7.9037-4.4116 6.1335-8.6621Z"
    />
    <circle cx={36.452} cy={82.274} r={4.39} fill="#1D1D1D" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m24.083 64.0992 5.6609 4.561-5.9083 4.2607-1.505-2.0871 3.1713-2.287-3.0333-2.4438 1.6144-2.0038Zm25.6137.5536 1.54 2.0614-3.1842 2.3788 3.056 2.4622-1.6143 2.0037-5.6383-4.5427 5.8408-4.3634Z"
      clipRule="evenodd"
    />
  </svg>
);

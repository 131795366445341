import type * as React from 'react';
export const GiftIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={234}
    height={151}
    fill="none"
    viewBox="0 0 234 151"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#DDDBF9"
      d="M152.023 100.887c-1.192-1.7876-2.086-4.2711-4.371-4.9664-5.066-1.4901-15.397 2.0861-16.39 6.4574-.894 4.37 6.059 11.225 11.125 13.112 2.583.993 5.563-1.391 7.649-2.682"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M152.023 100.887c-1.192-1.7876-2.086-4.2711-4.371-4.9664-5.066-1.4901-15.397 2.0861-16.39 6.4574-.894 4.37 6.059 11.225 11.125 13.112 2.583.993 5.563-1.391 7.649-2.682"
    />
    <path
      fill="#DDDBF9"
      d="M179.937 117.377c2.086.398 4.47 1.59 6.556.398 4.669-2.583 9.437-12.417 6.954-16.192-2.483-3.7751-12.219-3.6757-17.086-1.391-2.484 1.093-2.98 4.868-3.576 7.252"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M179.937 117.377c2.086.398 4.47 1.59 6.556.398 4.669-2.583 9.437-12.417 6.954-16.192-2.483-3.7751-12.219-3.6757-17.086-1.391-2.484 1.093-2.98 4.868-3.576 7.252"
    />
    <path
      fill="#1D1D1D"
      d="M170.301 147.775c18.434 0 33.378-1.913 33.378-4.272s-14.944-4.271-33.378-4.271c-18.434 0-33.377 1.912-33.377 4.271s14.943 4.272 33.377 4.272ZM51.7914 149.861c27.3765 0 49.5696-2.846 49.5696-6.358 0-3.511-22.1931-6.357-49.5696-6.357s-49.5696 2.846-49.5696 6.357c0 3.512 22.1931 6.358 49.5696 6.358Z"
    />
    <path
      fill="#fff"
      d="M71.7583 27.3775C28.9437 21.9139 6.9901 58.3709 3.3146 83.6027.0364 106.55 7.884 122.245 20.1026 131.483c-3.9735 4.868-6.9536 8.444-3.9735 10.828 3.3775 2.682 14.404 2.682 19.9669-2.781 5.1656 1.49 10.5298 2.285 15.6954 2.285 4.0728 0 7.947-.398 11.6225-1.292 1.8874 2.484 4.3709 3.576 6.8543 3.179 3.3775-.497 5.8609-3.576 6.3576-8.543 13.2119-8.146 23.4442-23.841 30.9932-48.7749 10.331-33.7748-10.2316-55.7284-35.8607-59.0066Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M71.7583 27.3775C28.9437 21.9139 6.9901 58.3709 3.3146 83.6027.0364 106.55 7.884 122.245 20.1026 131.483c-3.9735 4.868-6.9536 8.444-3.9735 10.828 3.3775 2.682 14.404 2.682 19.9669-2.781 5.1656 1.49 10.5298 2.285 15.6954 2.285 4.0728 0 7.947-.398 11.6225-1.292 1.8874 2.484 4.3709 3.576 6.8543 3.179 3.3775-.497 5.8609-3.576 6.3576-8.543 13.2119-8.146 23.4442-23.841 30.9932-48.7749 10.331-33.7748-10.2316-55.7284-35.8607-59.0066Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M108.513 83.404c-4.768 4.4702-12.119 7.1523-21.2581 6.7549-19.4702-.7947-29.2053-9.7351-29.6026-22.1523-.3974-13.3112 11.4238-24.3377 30.7947-23.245 6.0596.298 12.219 1.3907 17.086 4.2715"
    />
    <path
      fill="#fff"
      d="M92.7185 14.3643c.4967-.3974.8941-.8941 1.2914-1.3908 1.9868-2.7814 1.7881-6.3576-.4967-8.0463-2.2847-1.6888-5.7616-.7947-7.7483 1.9867-.3974.4967-.6954 1.0928-.8941 1.6888"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.472}
      d="M92.7185 14.3643c.4967-.3974.8941-.8941 1.2914-1.3908 1.9868-2.7814 1.7881-6.3576-.4967-8.0463-2.2847-1.6888-5.7616-.7947-7.7483 1.9867-.3974.4967-.6954 1.0928-.8941 1.6888"
    />
    <path
      fill="#D4F0E6"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="m117.652 124.629-17.086 3.179-16.6885-4.669 4.2715-27.6159 23.245.8941 15.795 5.7618-9.537 22.45Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="m127.189 102.179-19.272.496-19.768-7.1519M107.917 102.676l-7.351 25.132"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M94.7053 38.106c-2.5828 2.0861-5.5629 1.7881-6.6556-.596-1.0928-2.4835.1986-6.0596 2.7814-8.1457s5.5629-1.7881 6.6556.596c1.0928 2.3841-.1986 6.0596-2.7814 8.1457Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M95.202 27.5761c-2.6822 9.0398-11.4239 14.404-25.2318 10.3312-13.808-4.0729-17.0861-12.1192-12.9139-20.563 3.0794-6.2582 9.139-13.9072 22.5496-12.1192 14.3047 1.7881 18.2782 13.3113 15.5961 22.351Z"
    />
    <path
      fill="#000"
      d="M92.4692 24.6113c.3103-1.0525-.2913-2.1573-1.3438-2.4676-1.0525-.3103-2.1573.2914-2.4676 1.3439-.3103 1.0524.2914 2.1572 1.3439 2.4675 1.0525.3103 2.1572-.2914 2.4675-1.3438ZM74.8671 24.6793c.3103-1.0525-.2913-2.1572-1.3438-2.4675-1.0525-.3103-2.1572.2913-2.4675 1.3438-.3104 1.0525.2913 2.1573 1.3438 2.4676 1.0525.3103 2.1572-.2914 2.4675-1.3439Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M74.2417 35.2252c.298 3.1788-1.6887 5.4636-4.2715 5.0662-2.5828-.3973-4.8676-3.3775-5.1656-6.6556-.298-3.2782 1.6888-5.4636 4.2716-5.0662 2.5827.3973 4.8675 3.4768 5.1655 6.6556Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M86.3609 26.4834c.4967 1.9868-1.7881 3.3775-3.3775 3.0795-1.5894-.298-2.7815-1.7881-2.4834-3.3775"
    />
    <path
      fill="#fff"
      d="M72.0563 8.3046c-.1987-.596-.3974-1.192-.7947-1.788-1.6888-2.9802-5.0663-4.1723-7.5497-2.7815-2.4835 1.3907-2.9801 4.9668-1.2914 7.947.298.596.6954 1.0927 1.1921 1.49"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.472}
      d="M72.0563 8.3046c-.1987-.596-.3974-1.192-.7947-1.788-1.6888-2.9802-5.0663-4.1723-7.5497-2.7815-2.4835 1.3907-2.9801 4.9668-1.2914 7.947.298.596.6954 1.0927 1.1921 1.49"
    />
    <path
      fill="#fff"
      d="M47.2218 38.3047c.7948-1.9868 2.4835-4.0729 1.6888-6.3577-1.6888-5.0662-10.3311-11.6225-14.5033-9.9337-4.1722 1.6887-5.9603 11.2251-4.6689 16.49.596 2.5828 4.1722 3.8742 6.457 4.8676"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.472}
      d="M47.2218 38.3047c.7948-1.9868 2.4835-4.0729 1.6888-6.3577-1.6888-5.0662-10.3311-11.6225-14.5033-9.9337-4.1722 1.6887-5.9603 11.2251-4.6689 16.49.596 2.5828 4.1722 3.8742 6.457 4.8676"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="m92.3212 106.848 6.9536-.298M93.3145 111.715l3.8742 1.192M90.0364 115.788l5.1656 3.974"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M79.3079 68.8013 70.5662 53.702"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M74.9371 66.0199c2.6334 0 4.7682-2.1348 4.7682-4.7682 0-2.6334-2.1348-4.7682-4.7682-4.7682-2.6334 0-4.7682 2.1348-4.7682 4.7682 0 2.6334 2.1348 4.7682 4.7682 4.7682Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M104.639 56.7814 92.9172 68.404"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M98.7781 67.3112c2.6339 0 4.7679-2.1348 4.7679-4.7682 0-2.6334-2.134-4.7682-4.7679-4.7682-2.6334 0-4.7682 2.1348-4.7682 4.7682 0 2.6334 2.1348 4.7682 4.7682 4.7682Z"
    />
    <path
      fill="#FEE3B5"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M87.255 90.159c9.139.3974 16.391-2.2848 21.258-6.755.596-2.2847.994-4.4702 1.292-6.5563-14.2057-5.1655-39.5368-2.8808-49.9673 0C63.7119 84.4967 72.851 89.563 87.255 90.159Z"
    />
    <path
      fill="#fff"
      d="M70.1689 75.2583c-.4967 3.3775-.8941 6.8543-2.1855 10.0331l2.1855-10.0331Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M70.1689 75.2583c-.4967 3.3775-.8941 6.8543-2.1855 10.0331"
    />
    <path
      fill="#fff"
      d="M84.5728 73.5695c-.4967 3.3775-1.7881 12.9139-3.0795 16.0927l3.0795-16.0927Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M84.5728 73.5695c-.4967 3.3775-1.7881 12.9139-3.0795 16.0927"
    />
    <path
      fill="#fff"
      d="M98.2814 74.3643c0 5.0662-1.192 12.1192-2.4834 15.298l2.4834-15.298Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M98.2814 74.3643c0 5.0662-1.192 12.1192-2.4834 15.298"
    />
    <path
      fill="#fff"
      d="M67.4867 116.483c3.8742 1.292 7.351.696 12.1192-2.384 7.053 2.484 12.8146.1 13.7086-3.278 1.1921-4.569-6.2582-9.536-14.2053-9.536"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M67.4867 116.483c3.8742 1.292 7.351.696 12.1192-2.384 7.053 2.484 12.8146.1 13.7086-3.278 1.1921-4.569-6.2582-9.536-14.2053-9.536"
    />
    <path
      fill="#fff"
      d="M211.725 114.397c-6.258-8.344-18.874.696-27.914-5.761-12.119-8.742-23.94-34.8679-32.483-30.6957-14.503 6.9537-17.583 52.4507 16.689 63.4767 19.867 6.358 40.927-4.569 45.695-15.993 5.96 2.384 17.583 2.483 17.682-7.649.099-4.967-1.887-10.331-5.96-13.709-4.967-4.0727-9.636.994-13.709 10.331Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.25}
      d="M211.725 114.397c-6.258-8.344-18.874.696-27.914-5.761-12.119-8.742-23.94-34.8679-32.483-30.6957-14.503 6.9537-17.583 52.4507 16.689 63.4767 19.867 6.358 40.927-4.569 45.695-15.993 5.96 2.384 17.583 2.483 17.682-7.649.099-4.967-1.887-10.331-5.96-13.709-4.967-4.0727-9.636.994-13.709 10.331Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M211.725 114.397c-6.258-8.344-18.874.696-27.914-5.761-12.119-8.742-23.94-34.8679-32.483-30.6957-14.503 6.9537-17.583 52.4507 16.689 63.4767 19.867 6.358 40.927-4.569 45.695-15.993 5.96 2.384 17.583 2.483 17.682-7.649.099-4.967-1.887-10.331-5.96-13.709-4.967-4.0727-9.636.994-13.709 10.331Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M145.268 84.1987c1.093-1.6888 3.278-2.1855 4.967-1.1921 1.689 1.0927 2.186 3.2782 1.192 4.9669M154.705 91.351c1.093-1.6887 3.278-2.1854 4.967-1.192 1.689 1.0927 2.186 3.2781 1.192 4.9668"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M153.811 109.43c3.974.398 10.927-2.483 12.219-10.3306"
    />
    <path
      fill="#DDDBF9"
      d="M159.275 113.007c-1.192-1.788-2.086-4.272-4.371-4.967-5.066-1.49-15.397 2.086-16.391 6.457-.894 4.371 6.06 11.225 11.126 13.112 2.583.994 5.563-1.39 7.649-2.682"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M159.275 113.007c-1.192-1.788-2.086-4.272-4.371-4.967-5.066-1.49-15.397 2.086-16.391 6.457-.894 4.371 6.06 11.225 11.126 13.112 2.583.994 5.563-1.39 7.649-2.682"
    />
  </svg>
);

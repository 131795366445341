import type * as React from 'react';
export const AccountingColorIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={100}
    height={100}
    fill="none"
    viewBox="0 0 100 100"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#FFDC9B"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M9.3506 13.2367H71.66c1.3024 0 2.6461 1.0542 2.6461 2.3546v60.8555c0 1.0403-1.1326 1.8837-2.1745 1.8837H9.3506c-1.042 0-2.5983-.8434-2.5983-1.8837V15.5913c0-1.3004 1.2959-2.3546 2.5983-2.3546Z"
    />
    <path
      fill="#fff"
      d="M74.3062 11.3482c0-1.435-1.3068-2.5982-2.9188-2.5982H9.6104c-1.612 0-2.858 1.1633-2.858 2.5982v12.9912h67.5538V11.3482Z"
    />
    <path
      fill="#fff"
      d="M74.3062 11.3482c0-1.435-1.3068-2.5982-2.9188-2.5982H9.6104c-1.612 0-2.858 1.1633-2.858 2.5982v12.9912h67.5538V11.3482Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M74.3062 11.3482c0-1.435-1.3068-2.5982-2.9188-2.5982H9.6104c-1.612 0-2.858 1.1633-2.858 2.5982v12.9912h67.5538V11.3482Z"
    />
    <circle
      cx={2.774}
      cy={2.774}
      r={2.774}
      fill="#1D1D1D"
      transform="matrix(-1 0 0 1 69.11 13.77)"
    />
    <circle
      cx={2.774}
      cy={2.774}
      r={2.774}
      fill="#1D1D1D"
      transform="matrix(-1 0 0 1 59.651 13.77)"
    />
    <circle
      cx={2.774}
      cy={2.774}
      r={2.774}
      fill="#1D1D1D"
      transform="matrix(-1 0 0 1 50.194 13.77)"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M30.8705 36.2244a13.775 13.775 0 0 0-8.7391 3.1269 13.775 13.775 0 0 0-3.4099 17.1424 13.7755 13.7755 0 1 0 21.8898-16.2346l-9.7408 9.7408V36.2244Z"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M30.8705 36.2243c3.8738 0 7.374 1.5989 9.8769 4.1728"
    />
    <rect
      width={39.21}
      height={47.089}
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      rx={6.406}
      transform="matrix(-1 0 0 1 93.911 44.472)"
    />
    <rect
      width={24.619}
      height={9.948}
      fill="#D6CEF9"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      rx={3.203}
      transform="matrix(-1 0 0 1 86.615 50.954)"
    />
    <circle
      cx={2.905}
      cy={2.905}
      r={2.905}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 80.806 73.827)"
    />
    <circle
      cx={2.905}
      cy={2.905}
      r={2.905}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 71.347 73.827)"
    />
    <circle
      cx={2.905}
      cy={2.905}
      r={2.905}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 61.89 73.827)"
    />
    <circle
      cx={2.905}
      cy={2.905}
      r={2.905}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 80.806 83.556)"
    />
    <circle
      cx={2.905}
      cy={2.905}
      r={2.905}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 71.347 83.556)"
    />
    <circle
      cx={2.905}
      cy={2.905}
      r={2.905}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 61.89 83.556)"
    />
  </svg>
);

import type * as React from 'react';
export const CastleIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={162}
    height={130}
    fill="none"
    viewBox="0 0 162 130"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#FED491"
      d="M41.7772 113.564h30.4289V45.6808H41.7772v67.8832Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M73.7021 115.064H40.2826V44.18h33.4195v70.884Zm-2.9906-3.001V47.1803H43.2732v64.8827h27.4383Z"
      clipRule="evenodd"
    />
    <path fill="#FED491" d="M25.491 113.602h16.2986V68.3711H25.491v45.2309Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M43.2855 115.103H23.9964V66.8716h19.2891v48.2314Zm-2.9906-3.001v-42.23h-13.308v42.23h13.308Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M25.5198 100.781h46.7275v3H25.5198v-3ZM25.5198 89.4551h46.7275v3.0003H25.5198v-3.0003ZM25.5198 78.1287h46.7275v3.0003H25.5198v-3.0003ZM41.8156 66.801h30.4289v3.0004H41.8156V66.801ZM41.8156 55.5499h30.4289v3.0004H41.8156v-3.0004Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M40.3197 113.532V74.0017h2.9906v39.5303h-2.9906ZM58 14v33h-3V14h3Z"
      clipRule="evenodd"
    />
    <path fill="#fff" d="M56.996 5.9694v18.8273l21.831-9.6762-21.831-9.1511Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m55 3.7164 27.1133 11.3654L55 27.0992V3.7164Zm2.9906 4.5053v14.2719l16.5488-7.3349-16.5488-6.937Z"
      clipRule="evenodd"
    />
    <path fill="#FED491" d="M118.972 45.7244H88.543v67.8836h30.429V45.7244Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M87.0505 44.2239H120.47v70.8841H87.0505V44.2239Zm2.9906 3.0003v64.8828h27.4379V47.2242H90.0411Z"
      clipRule="evenodd"
    />
    <path fill="#FED491" d="M135.273 68.302h-16.299v45.231h16.299V68.302Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M117.478 66.801h19.289v48.231h-19.289V66.801Zm2.991 3.0004v42.2306h13.308V69.8014h-13.308Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M135.196 103.781H88.543v-3h46.653v3ZM135.196 92.4554H88.543v-3.0003h46.653v3.0003ZM135.196 81.129H88.543v-3.0003h46.653v3.0003ZM118.972 69.8014H88.543V66.801h30.429v3.0004ZM118.972 58.5503H88.543v-3.0004h30.429v3.0004Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M117.478 113.532V74.0017h2.991v39.5303h-2.991ZM103.796 14.3702v32.8541h-2.99V14.3702h2.99Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M102.302 5.9694v18.8273l21.831-9.6762-21.831-9.1511Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="m100.806 3.7164 27.113 11.3654-27.113 12.0174V3.7164Zm2.99 4.5053v14.2719l16.549-7.3349-16.549-6.937Z"
      clipRule="evenodd"
    />
    <path fill="#fff" d="M157.178 113.532H3.5385v13.277H157.178v-13.277Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M2 112h158v16H2v-16Zm3.0167 2.949v10.102H156.983v-10.102H5.0167Z"
      clipRule="evenodd"
    />
    <path
      fill="#BCB6F3"
      d="M98.9373 113.533H61.7797V71.678c0-10.2763 8.2987-18.6023 18.5414-18.6023 10.2426 0 18.5414 8.326 18.5414 18.6023v41.855h.0748Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M81 54.9787c-9.3996 0-17.0149 7.5992-17.0149 16.9787v40.0636h34.0298V71.9574c0-9.3795-7.6153-16.9787-17.0149-16.9787Zm18.5821 58.5323V115H61V71.9574C61 60.9328 69.9518 52 81 52s20 8.9328 20 19.9574v41.5536h-1.4179Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      d="M86.1679 80.3785c0-2.8504-2.2429-5.1007-5.084-5.1007-2.841 0-5.0839 2.2503-5.0839 5.1007 0 2.1752 1.4205 4.0505 3.3644 4.8006l-2.4672 9.4511h8.523l-2.4672-9.4511c1.7944-.7501 3.2149-2.6254 3.2149-4.8006Z"
    />
  </svg>
);

import type * as React from 'react';
export const MagicSyncIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={128}
    height={80}
    fill="none"
    viewBox="0 0 128 80"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#BDB1F5"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m79.5629 22.3203-.9099 1.1629.9099-1.1629c-7.7077-6.0312-18.6871-6.8889-27.4233-1.3252-5.8618 3.7332-9.5319 9.6091-10.6451 15.9448l-1.8611.2461c-2.0423.2701-2.8998 2.7558-1.4584 4.2277l6.9969 7.1444c1.1662 1.1908 3.1489.9286 3.9655-.5243l4.8996-8.7175c1.0093-1.7959-.4648-3.9733-2.5071-3.7033l-1.5.1983c1.1302-3.2204 3.3076-6.0945 6.4071-8.0684 5.7877-3.6859 13.0747-3.1295 18.1959.8778 1.7399 1.3614 4.2539 1.0546 5.6152-.6852 1.3614-1.7398 1.0546-4.2538-.6852-5.6152Zm4.6495 11.2383-.9882.9677.9882-.9677c-1.1662-1.1908-3.1489-.9286-3.9655.5243l-4.8996 8.7175c-1.0093 1.7959.4648 3.9734 2.5071 3.7033l1.9476-.2575c-1.1266 3.2349-3.3088 6.1233-6.4197 8.1044-5.7877 3.686-13.0747 3.1295-18.1959-.8778-1.7398-1.3614-4.2539-1.0546-5.6152.6852-1.3614 1.7399-1.0546 4.2539.6852 5.6152 7.7077 6.0312 18.6872 6.8889 27.4233 1.3252 5.8728-3.7401 9.5454-9.6304 10.6512-15.98l1.42-.1877c2.0423-.2701 2.8998-2.7558 1.4584-4.2277l-6.9969-7.1444Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M14.2315 64.2706C12.939 62.6088 8 61.9626 8 61.9626s3.6216-.5503 5.7699-2.3079c2.5388-2.0772 2.5388-7.6163 2.5388-7.6163s.6923 5.3083 2.3079 7.1547c1.6156 1.8463 6.9239 2.3079 6.9239 2.3079s-5.4468.5539-6.9239 2.7696c-1.4771 2.2156-2.3079 7.3854-2.3079 7.3854s-.7847-5.7237-2.0772-7.3854ZM108.231 24.2706c-1.292-1.6618-6.231-2.308-6.231-2.308s3.622-.5503 5.77-2.3079c2.539-2.0772 2.539-7.6163 2.539-7.6163s.692 5.3083 2.308 7.1547c1.615 1.8463 6.924 2.3079 6.924 2.3079s-5.447.5539-6.924 2.7696c-1.477 2.2156-2.308 7.3854-2.308 7.3854s-.785-5.7237-2.078-7.3854Z"
    />
  </svg>
);

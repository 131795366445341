import type * as React from 'react';
export const CashbagIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={118}
    height={108}
    fill="none"
    viewBox="0 0 118 108"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M34.1393 18.8655c.3052-.6105.407-1.221.5087-1.9333.5088-3.4595-1.3227-6.6137-4.2735-7.0207-2.849-.407-5.5962 2.035-6.105 5.4945-.1017.7122-.1017 1.3227 0 2.035"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M30.5831 8.4245c4.0489.5607 6.1329 4.7442 5.5494 8.7213-.1012.7086-.2266 1.5389-.6516 2.3889-.3705.741-1.2715 1.0413-2.0124.6708-.741-.3705-1.0413-1.2715-.6709-2.0124.1849-.3698.2633-.7593.3655-1.4746l.0009-.0061c.4323-2.9398-1.1452-5.0615-2.9944-5.3166l-.0072-.001c-1.7922-.256-3.9804 1.3238-4.4084 4.2248-.0812.5701-.081 1.0371.0005 1.6077.1171.8201-.4527 1.5799-1.2728 1.697-.8201.1172-1.5799-.4527-1.6971-1.2728-.1218-.8529-.1218-1.6063 0-2.4592l.0009-.0062v.0001c.5904-4.0148 3.894-7.317 7.7976-6.7617Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M45.4367 47.3797c-2.442 2.3402-5.4945 2.3402-6.8172-.1018-1.3228-2.442-.3053-6.2067 2.1367-8.547 2.442-2.3402 5.4945-2.3402 6.8173.1018 1.3227 2.3403.407 6.2067-2.1368 8.547Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M44.5691 38.4884c-.7521-.0077-1.7652.3578-2.775 1.3255-1.015.9727-1.7387 2.2512-2.065 3.5247-.3296 1.2865-.2229 2.4269.2093 3.2249.4007.7398.9943 1.0516 1.6854 1.0587.7522.0078 1.7652-.3577 2.7751-1.3255l.011-.0106.0112-.0103c1.0428-.9593 1.7622-2.2464 2.0773-3.5278.3198-1.3006.1893-2.434-.2308-3.1772l-.0066-.0118-.0065-.0119c-.4007-.7397-.9943-1.0516-1.6854-1.0587Zm4.3169-.3819c-.923-1.6946-2.5138-2.5997-4.286-2.6179-1.7153-.0177-3.4495.7869-4.8816 2.1594-1.4271 1.3675-2.4331 3.1416-2.8954 4.946-.4589 1.7914-.4129 3.7544.4775 5.3984.9221 1.7022 2.5161 2.6114 4.2924 2.6297 1.711.0176 3.4406-.7828 4.8706-2.1489 1.4949-1.3796 2.5012-3.1914 2.9481-5.0088.4423-1.7984.3707-3.763-.5256-5.3579Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M45.1289 36.4926c-1.9333 9.4627-10.4803 15.6695-24.9288 12.617-14.4484-3.0525-18.4167-10.989-14.8554-19.943 2.6455-6.6138 8.1399-14.9572 22.0797-14.245 14.652.7122 19.6377 12.1082 17.7045 21.571Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.0999 20.4572c-3.1702 2.5969-5.0852 6.0738-6.3619 9.2651-1.65 4.1497-1.479 7.8227.524 10.7894 2.0458 3.0299 6.2128 5.6438 13.2483 7.1302 6.9734 1.4733 12.3281.6728 16.1123-1.4551 3.7646-2.1169 6.1475-5.6418 7.0367-9.9945.8969-4.3897.175-9.1768-2.3987-12.9162-2.5423-3.6937-6.9974-6.5203-13.9089-6.8563l-.0037-.0002c-6.6305-.3388-11.1145 1.4705-14.2481 4.0376Zm14.3993-7.0338c-7.3083-.373-12.5409 1.6332-16.3004 4.713-3.723 3.0499-5.8779 7.051-7.2467 10.4731l-.0011.0027c-1.9106 4.8037-1.8779 9.5755.8247 13.5783 2.6602 3.9399 7.7016 6.8205 15.1145 8.3866 7.4751 1.5793 13.6181.8026 18.2028-1.7754 4.6044-2.5891 7.4617-6.8989 8.5056-12.0089 1.0365-5.0731.2321-10.7153-2.8668-15.2176-3.13-4.5477-8.4932-7.7751-16.2326-8.1518Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="M37.6018 25.2226c1.0876-.2832 1.7397-1.3945 1.4565-2.4821-.2832-1.0876-1.3945-1.7397-2.4821-1.4564-1.0876.2832-1.7397 1.3944-1.4564 2.482.2832 1.0876 1.3944 1.7397 2.482 1.4565ZM20.48 31.0216c1.0765-.3225 1.6878-1.4568 1.3652-2.5333-.3225-1.0766-1.4567-1.6879-2.5333-1.3653-1.0766.3225-1.6879 1.4568-1.3653 2.5334.3226 1.0765 1.4568 1.6878 2.5334 1.3652Z"
    />
    <path
      fill="#fff"
      d="M24.2717 46.0586c.5088 3.256-1.221 5.698-3.8665 5.4945-2.6455-.2035-5.291-3.0525-5.7997-6.3085-.5088-3.256 1.221-5.698 3.8665-5.4945 2.6455.2035 5.291 3.0525 5.7997 6.3085Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M16.5817 42.0645c-.4291.6085-.7021 1.6184-.4942 2.9486.2052 1.3138.8503 2.5714 1.7192 3.5136.8811.9554 1.8745 1.4663 2.7135 1.5309.7834.0602 1.373-.2484 1.7752-.8188.4291-.6085.7021-1.6184.4943-2.9486-.2053-1.3138-.8504-2.5714-1.7193-3.5136-.8811-.9554-1.8745-1.4663-2.7135-1.5309-.7834-.0602-1.373.2484-1.7752.8188ZM14.13 40.3357c.9968-1.4138 2.5949-2.2244 4.457-2.0811 1.8064.1389 3.4586 1.1542 4.6888 2.4882 1.2424 1.3472 2.1744 3.1421 2.4779 5.0843.3009 1.9258-.0366 3.7649-1.0065 5.1405-.9969 1.4138-2.595 2.2244-4.4571 2.0811-1.8064-.1389-3.4585-1.1543-4.6887-2.4882-1.2425-1.3472-2.1745-3.1421-2.478-5.0843-.3009-1.9258.0366-3.7649 1.0066-5.1405Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M13.1582 21.9165c-.407-.5087-.814-1.0174-1.4245-1.4244-2.7473-2.1368-6.4103-2.1368-8.2417.2035-1.8316 2.2384-1.0175 5.9015 1.7297 8.0382.5088.407 1.1193.814 1.7297 1.0175"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M12.6127 19.275c.7799.5317 1.2982 1.1802 1.6816 1.66l.0352.0439c.5175.6469.4126 1.5908-.2343 2.1083-.6469.5175-1.5908.4126-2.1084-.2343-.4196-.5245-.6947-.8531-1.0852-1.1134l-.0456-.0304-.0433-.0337c-2.3425-1.8219-4.9915-1.523-6.1395-.056l-.01.0128-.0103.0126c-1.1222 1.3715-.834 4.097 1.4897 5.9043l.008.0063.0081.0065c.458.3664.9052.6451 1.267.7657.786.262 1.2107 1.1115.9487 1.8974-.262.7859-1.1114 1.2107-1.8973.9487-.8551-.2851-1.6257-.8166-2.1845-1.2629-3.1607-2.4644-4.4966-7.0505-1.972-10.1531 2.5063-3.1859 7.145-2.9012 10.2921-.4827Z"
      clipRule="evenodd"
    />
    <ellipse cx={64.403} cy={103.363} fill="#1D1D1D" rx={45} ry={4} />
    <path
      fill="#fff"
      d="M13.6755 70.6378c0 25.3636 14.0909 33.8182 28.1819 33.8182h45.0909c14.0907 0 28.1817-8.4546 28.1817-33.8182 0-19.7273-14.091-45.0909-42.2726-45.0909H55.9483c-28.1819 0-42.2728 25.3636-42.2728 45.0909Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M13.6755 70.6378c0 25.3636 14.0909 33.8182 28.1819 33.8182h45.0909c14.0907 0 28.1817-8.4546 28.1817-33.8182 0-19.7273-14.091-45.0909-42.2726-45.0909H55.9483c-28.1819 0-42.2728 25.3636-42.2728 45.0909Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M13.6755 70.6378c0 25.3636 14.0909 33.8182 28.1819 33.8182h45.0909c14.0907 0 28.1817-8.4546 28.1817-33.8182 0-19.7273-14.091-45.0909-42.2726-45.0909H55.9483c-28.1819 0-42.2728 25.3636-42.2728 45.0909Z"
    />
    <path
      fill="#fff"
      d="M53.1301 3s0 22.5455 11.2728 22.5455C75.6756 25.5455 75.6756 3 75.6756 3H53.1301Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M53.1301 3s0 22.5455 11.2728 22.5455C75.6756 25.5455 75.6756 3 75.6756 3H53.1301Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M53.1301 3s0 22.5455 11.2728 22.5455C75.6756 25.5455 75.6756 3 75.6756 3H53.1301Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M61.4028 25.3633c-3 1-6.5 5-8 8.5M74.7776 34.6986c-.4723-3.1268-3.8149-7.2592-7.0069-9.3356"
    />
    <path
      fill="#1D1D1D"
      d="M58 70c0 .5523.4477 1 1 1h.2273c.5523 0 1-.4477 1-1v-2.2647a.9998.9998 0 0 1 .2403-.6503l.9838-1.1494c.0273-.0318.0734-.0069.098.0271l3.3498 4.624A.9999.9999 0 0 0 65.709 71h.1714c.8224 0 1.2932-.9374.8022-1.5971l-3.3211-4.4619a1.0001 1.0001 0 0 1 .0456-1.2511l2.6393-3.053c.5543-.6411.0988-1.6369-.7487-1.6369a.9895.9895 0 0 0-.7517.346l-4.2467 4.96c-.0231.027-.072.0425-.072.0069V60c0-.5523-.4477-1-1-1H59c-.5523 0-1 .4477-1 1v10ZM68.8149 70c0 .5523.4477 1 1 1h.1382c.5523 0 1-.4477 1-1v-4.0914c0-1.44.5523-2.16 1.6571-2.16.5564 0 1.1025-.3113 1.2045-.8583l.0211-.1132c.0935-.5009-.2075-1.0008-.7164-1.0276a4.9887 4.9887 0 0 0-.2598-.0066c-.8506 0-1.549.3417-1.9634.9142-.0206.0283-.0679.0451-.0679.0101v-.3586a.3942.3942 0 0 0-.3942-.3943h-.6192c-.5523 0-1 .4477-1 1V70Z"
    />
  </svg>
);

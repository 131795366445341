import type * as React from 'react';
export const NothingHereIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={92}
    height={55}
    fill="none"
    viewBox="0 0 92 55"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#1D1D1D"
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M48.5 48.1068c9.308 0 17.7706-.6375 23.9383-1.6808 3.0717-.5196 5.6456-1.1514 7.4861-1.8874.913-.3652 1.7331-.7898 2.3507-1.3003.6014-.4972 1.2249-1.2613 1.2249-2.2982s-.6235-1.801-1.2249-2.2982c-.6176-.5105-1.4377-.9351-2.3507-1.3003-1.8405-.736-4.4144-1.3678-7.4861-1.8874-6.1677-1.0433-14.6303-1.6808-23.9383-1.6808-9.308 0-17.7706.6375-23.9383 1.6808-3.0717.5196-5.6456 1.1514-7.4861 1.8874-.913.3652-1.7331.7898-2.3507 1.3003-.6014.4972-1.2249 1.2613-1.2249 2.2982s.6235 1.801 1.2249 2.2982c.6176.5105 1.4377.9351 2.3507 1.3003 1.8405.736 4.4144 1.3678 7.4861 1.8874 6.1677 1.0433 14.6303 1.6808 23.9383 1.6808Z"
    />
    <path
      fill="#F4F5F7"
      fillRule="evenodd"
      d="M81.3293 42.0739c.4398-.3663.6707-.7455.6707-1.1338 0-3.1296-14.9985-5.6667-33.5-5.6667S15 37.8105 15 40.9401c0 .3881.2307.7671.6701 1.1333l.0006.0005C18.7756 39.4876 32.2939 37.541 48.5 37.541c16.2061 0 29.7244 1.9466 32.8293 4.5329Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M58.8666 16.785c.2981-.5961.3974-1.1921.4967-1.8875.4967-3.3774-1.2914-6.4569-4.1722-6.8543-2.7814-.3973-5.4635 1.9868-5.9602 5.3643-.0994.6953-.0994 1.2914 0 1.9867"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.472}
      d="M58.8666 16.785c.2981-.5961.3974-1.1921.4967-1.8875.4967-3.3774-1.2914-6.4569-4.1722-6.8543-2.7814-.3973-5.4635 1.9868-5.9602 5.3643-.0994.6953-.0994 1.2914 0 1.9867"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M69.8932 43.6061c-2.3841 2.2848-5.3642 2.2848-6.6556-.0993-1.2914-2.3841-.298-6.0596 2.0861-8.3444 2.3841-2.2848 5.3642-2.2847 6.6556.0994 1.2914 2.2847.3974 6.0596-2.0861 8.3443Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M69.595 32.977c-1.8874 9.2384-10.2318 15.298-24.3378 12.3179-14.1059-2.9801-17.9801-10.7285-14.5033-19.4702 2.5828-6.457 7.9471-14.6026 21.5563-13.9073 14.3047.6954 19.1722 11.8212 17.2848 21.0596Z"
    />
    <path
      fill="#1D1D1D"
      d="M60.9239 24.3458c1.0618-.2765 1.6984-1.3614 1.4219-2.4232-.2765-1.0618-1.3614-1.6984-2.4232-1.4219-1.0618.2765-1.6984 1.3614-1.4219 2.4232.2765 1.0618 1.3614 1.6985 2.4232 1.4219ZM49.0435 27.3764c1.0511-.3149 1.6478-1.4222 1.3329-2.4733-.3149-1.0511-1.4222-1.6478-2.4733-1.3329-1.051.3149-1.6478 1.4222-1.3329 2.4733.3149 1.0511 1.4222 1.6478 2.4733 1.3329Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M49.2309 42.3148c.4967 3.1788-1.1921 5.5629-3.7748 5.3642-2.5828-.1986-5.1656-2.9801-5.6623-6.1589-.4967-3.1788 1.1921-5.5629 3.7748-5.3642 2.5828.1986 5.1656 2.9801 5.6623 6.1589Z"
    />
    <path
      fill="#fff"
      d="M39.3966 19.765c-.3974-.4966-.7947-.9933-1.3907-1.3907-2.6822-2.0861-6.2583-2.0861-8.0464.1987-1.7881 2.1854-.9934 5.7616 1.6887 7.8477.4967.3973 1.0928.7947 1.6888.9934"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.472}
      d="M39.3966 19.765c-.3974-.4966-.7947-.9933-1.3907-1.3907-2.6822-2.0861-6.2583-2.0861-8.0464.1987-1.7881 2.1854-.9934 5.7616 1.6887 7.8477.4967.3973 1.0928.7947 1.6888.9934"
    />
    <rect
      width={9}
      height={3}
      x={51}
      y={30.713}
      fill="#1D1D1D"
      rx={1.5}
      transform="rotate(-10.97 51 30.713)"
    />
  </svg>
);

import type * as React from 'react';
export const TransferUnimicroIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={307}
    height={201}
    fill="none"
    viewBox="0 0 307 201"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m6.4034 106.994 4.3555 12.397c.4397 1.251 1.6493 2.065 2.9743 2.002L115.5 116.5l-32.9386-13.282a2.9963 2.9963 0 0 0-1.1219-.218H9.2338c-2.068 0-3.5159 2.043-2.8304 3.994ZM300.597 106.994l-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#3B2D81"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m300.597 106.994-4.356 12.397a3.0013 3.0013 0 0 1-2.974 2.002L191.5 116.5l32.939-13.282c.356-.144.737-.218 1.122-.218h72.205c2.068 0 3.516 2.043 2.831 3.994Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M135.893 16H92.107C90.9433 16 90 16.9433 90 18.107v55.786c0 1.1637.9433 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V18.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M126.5 27.5h-25M126.5 36.5h-25M126.5 45.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M146.893 5h-43.786C101.943 5 101 5.9433 101 7.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V7.107C149 5.9433 148.057 5 146.893 5Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M137.5 16.5h-25M137.5 25.5h-25M137.5 34.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M247.893 24h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.1637.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.9433 2.107-2.107V26.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M238.5 35.5h-25M238.5 44.5h-25M238.5 53.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M86.893 96H43.107C41.9433 96 41 96.9433 41 98.107v55.786c0 1.164.9433 2.107 2.107 2.107h43.786c1.1637 0 2.107-.943 2.107-2.107V98.107C89 96.9433 88.0567 96 86.893 96Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M77.5 107.5h-25M77.5 116.5h-25M77.5 125.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M268.893 73h-43.786c-1.164 0-2.107.9433-2.107 2.107v55.786c0 1.164.943 2.107 2.107 2.107h43.786c1.164 0 2.107-.943 2.107-2.107V75.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M259.5 84.5h-25M259.5 93.5h-25M259.5 102.5h-25"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m8.407 103.373 15.7037 15.547A3.001 3.001 0 0 1 25 121.052v68.092c0 2.755-3.4004 4.053-5.2362 2L5.7637 175.487A2.9998 2.9998 0 0 1 5 173.488v-68.694c0-1.777 2.1443-2.671 3.407-1.421ZM298.593 103.373l-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#DED8FA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="m298.593 103.373-15.704 15.547a3.001 3.001 0 0 0-.889 2.132v68.092c0 2.755 3.4 4.053 5.236 2l14-15.657c.492-.55.764-1.262.764-1.999v-68.694c0-1.777-2.144-2.671-3.407-1.421Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M113.893 116H22.7192c-1.1637 0-2.107.943-2.107 2.107v71.786c0 1.164.9433 2.107 2.107 2.107h91.1738c1.164 0 2.107-.943 2.107-2.107v-71.786c0-1.164-.943-2.107-2.107-2.107ZM193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M193.107 116h91.174c1.163 0 2.107.943 2.107 2.107v71.786c0 1.164-.944 2.107-2.107 2.107h-91.174c-1.164 0-2.107-.943-2.107-2.107v-71.786c0-1.164.943-2.107 2.107-2.107Z"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M187.893 9h-19.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h19.786c1.164 0 2.107-.9433 2.107-2.107V11.107C190 9.9433 189.057 9 187.893 9Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M173 20.6267 177.125 25 184 17"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#80DBC1"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M77.893 44H58.107C56.9433 44 56 44.9433 56 46.107v19.786c0 1.1637.9433 2.107 2.107 2.107h19.786C79.0567 68 80 67.0567 80 65.893V46.107C80 44.9433 79.0567 44 77.893 44Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M63 55.6267 67.125 60 74 52"
    />
    <path
      fill="#fff"
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M76.1202 144.942v26.827H56v-31.298c0-1.186.4711-2.323 1.3096-3.161a4.4694 4.4694 0 0 1 3.1616-1.31"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M56 156.12c0-1.186.4711-2.323 1.3096-3.162a4.4726 4.4726 0 0 1 3.1616-1.309"
    />
    <path
      fill="#BDB2F5"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 144.942H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.161a4.4693 4.4693 0 0 0-3.1615-1.31h20.1202c1.1858 0 2.323.471 3.1615 1.31a4.4687 4.4687 0 0 1 1.3096 3.161v4.471Z"
    />
    <path
      fill="#FDC7BD"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.236}
      d="M85.0625 160.591H64.9423v-4.471c0-1.186-.4711-2.323-1.3096-3.162a4.4726 4.4726 0 0 0-3.1615-1.309h20.1202c1.1858 0 2.323.471 3.1615 1.309a4.4727 4.4727 0 0 1 1.3096 3.162v4.471Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      fill="#8C77EE"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M165.893 72h-33.786c-1.164 0-2.107.9433-2.107 2.107v19.786c0 1.1637.943 2.107 2.107 2.107h33.786c1.164 0 2.107-.9433 2.107-2.107V74.107c0-1.1637-.943-2.107-2.107-2.107Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M139 83.5h20M154 78l5.5 5.5L154 89M139 83.5h20"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m154 78 5.5 5.5L154 89"
    />
    <path
      fill="#0A2229"
      d="M203.271 156.615v-5.854h2.124v5.643c0 1.586.808 2.343 2.058 2.343 1.444 0 2.461-.917 2.461-3.363v-4.623h2.081v9.652h-2.077v-1.464h-.047c-.547 1.135-1.735 1.838-3.012 1.777-1.934.004-3.588-1.146-3.588-4.111ZM223.138 154.482v5.931h-2.081v-5.579c0-1.651-.851-2.342-2.248-2.342-1.53 0-2.609.898-2.609 3.301v4.62h-2.081v-9.648h2.081v1.463h.042c.633-1.146 1.88-1.838 3.203-1.777 2.101 0 3.693 1.108 3.693 4.031ZM225.257 160.417v-9.652h2.081v1.608h-1.223l1.223 1.483v6.557h-2.081v.004Z"
    />
    <path
      fill="#0A2229"
      d="M227.366 150.765h-2.081v9.652h2.081v-9.652ZM229.567 160.417v-9.652h2.081v1.505h.043c.493-1.161 1.673-1.887 2.95-1.815 1.328-.076 2.543.726 2.97 1.964h.043c.508-1.223 1.735-2.006 3.075-1.968 1.844 0 3.354 1.131 3.354 3.989v5.973h-2.081v-5.869c0-1.338-.679-2.048-1.933-2.048-1.274 0-2.209.813-2.209 2.904v5.009h-2.081v-5.865c0-1.338-.68-2.048-1.953-2.048-1.254 0-2.186.813-2.186 2.923v4.994h-2.073v.004ZM249.88 155.59c0-3.072 2.228-5.139 5.392-5.139 1.192-.015 2.365.329 3.355.982l-.975 1.735a4.7474 4.7474 0 0 0-2.446-.73c-1.89 0-3.121 1.337-3.121 3.152 0 1.815 1.231 3.153 3.121 3.153a4.7222 4.7222 0 0 0 2.442-.73l.975 1.735c-.99.653-2.159.997-3.355.982-3.16 0-5.388-2.067-5.388-5.14ZM265.413 150.47v2.404h-.318c-2.039 0-3.122.959-3.122 3.259v4.283h-2.081v-9.651h2.081v2.109h.043c.384-1.445 1.732-2.442 3.246-2.404h.151ZM227.564 148.5c0-.68-.56-1.231-1.251-1.231-.691 0-1.25.551-1.25 1.231s.559 1.23 1.25 1.23c.33-.008.641-.134.882-.359.229-.233.361-.547.369-.871ZM248.494 148.5c0-.68-.559-1.231-1.251-1.231-.691 0-1.25.551-1.25 1.231s.559 1.23 1.25 1.23c.33-.008.641-.134.882-.359.229-.233.361-.547.369-.871ZM248.284 150.766h-2.081v9.652h2.081v-9.652ZM270.336 150.464l-.012.003v2.014c.027 0 .055-.004.082-.004 1.751-.061 3.218 1.288 3.277 3.008.058 1.719-1.309 3.167-3.056 3.224-1.747.058-3.218-1.287-3.277-3.007-.031-.791.567-.47 2.074.058v-2.037c-3.378-1.272-4.201-.451-4.112 2.044.101 2.832 2.512 5.044 5.389 4.949 2.877-.1 5.125-2.473 5.028-5.304-.101-2.831-2.516-5.048-5.393-4.948Z"
    />
  </svg>
);

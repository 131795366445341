import type * as React from 'react';
export const CleaningIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={102}
    height={94}
    fill="none"
    viewBox="0 0 102 94"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#9591AA"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M80.7546 43.0894c-1.7479.7107-5.8713-1.0011-5.8713-1.0011s2.5646 2.0502 3.4444 4.3772c1.0397 2.7501-1.4426 7.0497-1.4426 7.0497s2.9164-3.8102 4.9978-4.5193c2.0815-.7092 6.4088 1.3114 6.4088 1.3114s-3.9797-2.8709-4.1333-5.2527c-.1536-2.3817 1.5183-6.767 1.5183-6.767s-3.1742 4.0912-4.9221 4.8018ZM88.6956 77.8515c.2586-1.869 3.8027-4.5841 3.8027-4.5841s-3.0578 1.1959-5.513.7943c-2.9015-.4746-5.3838-4.7741-5.3838-4.7741s1.8414 4.4307 1.4148 6.5879c-.4265 2.1572-4.3401 4.8944-4.3401 4.8944s4.4761-2.011 6.6156-.9532c2.1395 1.0579 5.1013 4.6984 5.1013 4.6984s-1.956-4.7945-1.6975-6.6636Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M34.3259 76.7327c6.0964-1.6677 20.1011-8.1733 22.5045-19.5864l-1.877-1.3536c-8.8305 7.3983-16.4211 9.8081-24.9046 12.3505-.5572.167-.8405.7864-.6014 1.3166l2.8157 6.245c.3613.8014 1.2149 1.2598 2.0628 1.0279Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M54.8567 72.7836c4.0003-8.8709.3295-15.7008-1.3566-18.4649 0 0-.3113 7.6101-4.6269 12.716-4.0229 4.7595-7.6104 7.1059-15.1472 10.4085-.5359.2348-.747.8892-.4423 1.3887l3.7158 6.0914c.4517.7406 1.3452 1.0924 2.1618.7998 4.8656-1.7436 12.5939-6.0615 15.6954-12.9395Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M61.7898 70.2515c.9163-9.8489-4.8679-15.2026-7.4015-17.303 0 0 2.2137 7.4186-.249 13.7521-2.2974 5.9083-4.9736 9.3493-11.138 15.0203-.4306.3961-.4176 1.0836.0328 1.457l5.6163 4.656c.6678.5536 1.6266.5964 2.3043.055 4.1022-3.2773 10.1236-9.9892 10.8351-17.6374Z"
    />
    <path
      fill="#fff"
      d="M7.8005 16.6945 49.001 54.521l7.8581-2.2119-45.367-39.7314c-1.1456-1.0033-2.8868-.8914-3.8946.2503-.9966 1.1291-.9063 2.848.203 3.8665Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M7.8005 16.6945 49.001 54.521l7.8581-2.2119-45.367-39.7314c-1.1456-1.0033-2.8868-.8914-3.8946.2503-.9966 1.1291-.9063 2.848.203 3.8665Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M7.8005 16.6945 49.001 54.521l7.8581-2.2119-45.367-39.7314c-1.1456-1.0033-2.8868-.8914-3.8946.2503-.9966 1.1291-.9063 2.848.203 3.8665Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeWidth={2.5}
      d="M51.9885 49.7803c-2.7425.7719-4.0664 3.1023-3.5598 4.9019.5065 1.7997 2.8516 3.0973 5.5941 2.3253 2.7425-.772 4.0664-3.1023 3.5598-4.9019-.5065-1.7997-2.8516-3.0973-5.5941-2.3253Z"
    />
  </svg>
);

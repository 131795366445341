import type * as React from 'react';
export const StairsDoorIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={196}
    height={158}
    fill="none"
    viewBox="0 0 196 158"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path fill="#FED491" d="M170 70h-37v67h37V70Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M132 68h40v69h-40V68Zm3 2.9206v63.1584h34V70.9206h-34Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M170 126h-37v-3h37v3ZM170 115h-37v-3h37v3ZM170 104h-37v-3h37v3ZM170 93h-37v-3h37v3ZM170 82h-37v-3h37v3Z"
      clipRule="evenodd"
    />
    <path
      fill="#BCB6F3"
      d="M169.937 69.5331H132.78V27.678c0-10.2763 8.298-18.6023 18.541-18.6023s18.542 8.326 18.542 18.6023v41.8551h.074Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M152 10.9787c-9.4 0-17.015 7.5992-17.015 16.9787v40.0639h34.03V27.9574c0-9.3795-7.615-16.9787-17.015-16.9787ZM172 71h-40V27.9574C132 16.9328 140.952 8 152 8s20 8.9328 20 19.9574V71Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      d="M157.168 36.3785c0-2.8504-2.243-5.1007-5.084-5.1007-2.841 0-5.084 2.2503-5.084 5.1007 0 2.1752 1.421 4.0505 3.364 4.8006l-2.467 9.4511h8.523l-2.467-9.4511c1.794-.7501 3.215-2.6254 3.215-4.8006Z"
    />
    <path fill="#FED491" d="M151 81h-37v54h37V81Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M113 79h40v58h-40V79Zm3 2.9206v52.1584h34V81.9206h-34Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M151 126h-37v-3h37v3ZM151 115h-37v-3h37v3ZM151 104h-37v-3h37v3ZM151 93h-37v-3h37v3Z"
      clipRule="evenodd"
    />
    <path fill="#FED491" d="M132 92H95v44h37V92Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M94 90h40v47H94V90Zm3 2.9206v41.1584h34V92.9206H97Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M132 126H95v-3h37v3ZM132 115H95v-3h37v3ZM132 104H95v-3h37v3Z"
      clipRule="evenodd"
    />
    <path fill="#FED491" d="M113 103H76v33h37v-33Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M75 101h40v36H75v-36Zm3 2.921v30.158h34v-30.158H78Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M113 126H76v-3h37v3ZM113 115H76v-3h37v3Z"
      clipRule="evenodd"
    />
    <path fill="#FED491" d="M93 114H56v21h37v-21Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M55 112h40v25H55v-25Zm3 2.921v19.158h34v-19.158H58Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M93 126H56v-3h37v3Z"
      clipRule="evenodd"
    />
    <path fill="#FED491" d="M74 125H37v11h37v-11Z" />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M36 123h40v14H36v-14Zm3 2.921v8.158h34v-8.158H39Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M9.5 135.5h177v13H9.5z"
    />
  </svg>
);

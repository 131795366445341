import type * as React from 'react';
export const SquadIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={223}
    height={84}
    fill="none"
    viewBox="0 0 223 84"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M35.6906 46C24.1312 46 13.9508 51.9038 8 60.8606c7.0338 11.9406 20.0234 19.9531 34.8844 19.9531 9.5446 0 18.3172-3.3051 25.2358-8.8332C64.8159 57.1149 51.5512 46 35.6906 46Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.899}
      d="M8 60.8606C13.9508 51.9038 24.1312 46 35.6906 46c15.8606 0 29.1253 11.1149 32.4296 25.9805"
    />
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.899}
      d="M42.6802 57.2171c10.5662 0 19.1319-8.5656 19.1319-19.1319 0-10.5662-8.5657-19.1318-19.1319-19.1318-10.5662 0-19.1319 8.5656-19.1319 19.1318 0 10.5663 8.5657 19.1319 19.1319 19.1319Z"
    />
    <path
      fill="#1D1D1D"
      d="M32.785 40.0578c.9772-.5692 1.1909-2.0238.4773-3.2489-.7136-1.2252-2.0842-1.7569-3.0614-1.1878-.9772.5692-1.1909 2.0238-.4773 3.249.7136 1.2251 2.0842 1.7569 3.0614 1.1877ZM49.2899 30.4405c.9772-.5692 1.1909-2.0238.4773-3.2489-.7136-1.2252-2.0842-1.7569-3.0614-1.1878-.9772.5692-1.1909 2.0238-.4773 3.249.7136 1.2251 2.0842 1.7569 3.0614 1.1877Z"
    />
    <path
      fill="#FFB938"
      d="M53.9276 38.2365c1.8736 0 3.3924-1.5188 3.3924-3.3924s-1.5188-3.3924-3.3924-3.3924-3.3924 1.5188-3.3924 3.3924 1.5188 3.3924 3.3924 3.3924ZM34.3379 49.6362c1.8735 0 3.3923-1.5189 3.3923-3.3924 0-1.8736-1.5188-3.3924-3.3923-3.3924-1.8736 0-3.3924 1.5188-3.3924 3.3924 0 1.8735 1.5188 3.3924 3.3924 3.3924Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2.899}
      d="M46.3492 35.1785c-1.3142 3.1785-4.5843 5.0733-8.0073 4.676"
    />
    <path
      stroke="#1D1D1D"
      strokeWidth={2.994}
      d="M81.104 42.1377c0 21.5176-17.4435 38.9611-38.9612 38.9611-21.5176 0-38.9611-17.4435-38.9611-38.9611S20.6252 3.1766 42.1428 3.1766c21.5177 0 38.9612 17.4435 38.9612 38.9611Z"
    />
    <path
      fill="#1D1D1D"
      d="M109.105 82.5903c22.344 0 40.458-18.1136 40.458-40.458 0-22.3443-18.114-40.458-40.458-40.458-11.4316 0-21.7554 4.7408-29.1132 12.3635a47.3274 47.3274 0 0 1 1.7438 2.511C88.5726 9.2377 98.305 4.6681 109.105 4.6681c20.691 0 37.464 16.7733 37.464 37.4642s-16.773 37.4642-37.464 37.4642c-10.5231 0-20.0326-4.3382-26.8378-11.3237L81 71.2353c7.2783 7.0301 17.1864 11.355 28.105 11.355Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M151.8 70.2267c7.358 7.6227 17.681 12.3636 29.113 12.3636 22.344 0 40.458-18.1137 40.458-40.458 0-22.3443-18.114-40.458-40.458-40.458-11.431 0-21.755 4.7408-29.113 12.3635a47.8077 47.8077 0 0 1 1.744 2.511c6.837-7.3112 16.569-11.8807 27.369-11.8807 20.691 0 37.464 16.7733 37.464 37.4642s-16.773 37.4642-37.464 37.4642c-10.8 0-20.532-4.5696-27.369-11.8808a47.8077 47.8077 0 0 1-1.744 2.511Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M179.881 53.0942c-11.967 0-22.267 7.134-26.881 17.381 7.048 6.5753 16.72 10.6325 27.393 10.6325 10.307 0 19.681-3.784 26.659-9.9655-4.46-10.6031-14.946-18.048-27.171-18.048Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M179.881 53.0942c-11.967 0-22.267 7.134-26.881 17.381 7.048 6.5753 16.72 10.6325 27.393 10.6325 10.307 0 19.681-3.784 26.659-9.9655-4.46-10.6031-14.946-18.048-27.171-18.048Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m169.127 61.7706.564-10.3923c.014-.2656.3-.423.534-.294l9.594 5.2947c.256.1414.252.5112-.008.6415l-10.157 5.0976c-.249.1248-.542-.0685-.527-.3475Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m188.957 62.9364.568-10.3921c.015-.2656-.252-.4582-.498-.3597l-10.103 4.0418c-.27.108-.305.4757-.062.638l9.535 6.3503c.233.1555.545.0006.56-.2783Z"
    />
    <path
      fill="#FED491"
      d="M180.458 56.9525c10.099 0 18.286-8.1869 18.286-18.2859 0-10.0991-8.187-18.286-18.286-18.286-10.099 0-18.286 8.1869-18.286 18.286 0 10.099 8.187 18.2859 18.286 18.2859Z"
    />
    <path
      fill="#FFB938"
      d="M168.35 45.8979c1.168.3346 2.385-.3408 2.72-1.5086.335-1.1677-.341-2.3856-1.509-2.7202-1.167-.3346-2.385.3408-2.72 1.5086-.334 1.1677.341 2.3856 1.509 2.7202ZM185.47 51.4136c1.167.3346 2.385-.3409 2.72-1.5086.335-1.1678-.341-2.3857-1.509-2.7203-1.167-.3346-2.385.3408-2.72 1.5086-.335 1.1678.341 2.3857 1.509 2.7203Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M172.662 47.5122c1.666 2.8994 5.367 3.8992 8.266 2.2332M186.492 45.2806c.54-1.5505-.279-3.2452-1.83-3.785-1.55-.5399-3.245.2795-3.785 1.83M176.398 41.8041c.54-1.5506-.28-3.2452-1.83-3.7851-1.551-.5399-3.245.2795-3.785 1.8301"
    />
    <path
      fill="#1D1D1D"
      fillOpacity={0.66}
      d="M175.608 32.6311c-6.927-2.5468-9.732-2.4338-10.132-4.4583 2.401-1.9829 10.098-9.4009 17.467-7.4852 3.198.8314 10.867 5.1086 12.784 9.5117 6.456 10.6992-1.773 22.4062-1.135 19.4467.834-3.8642 2.312-5.784-.211-9.8515-1.777-2.8654-8.442-5.0928-13.244-6.284l-.732-3.7757c-.977-.1509-3.802 2.9778-4.797 2.8963Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m165.754 28.4387 10.902 3.6085 3.411-2.2042.967 3.4897 2.583.8449c6.621 2.0461 14.191 5.8858 12.096 13.4458l-.499 1.8568"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M180.458 56.9525c10.099 0 18.286-8.1869 18.286-18.2859 0-10.0991-8.187-18.286-18.286-18.286-10.099 0-18.286 8.1869-18.286 18.286 0 10.099 8.187 18.2859 18.286 18.2859Z"
    />
    <path
      fill="#fff"
      d="M83 70.6197c4.7115-14.0678 17.839-23.9244 31.633-23.9244 12.119 0 21.889 5.9017 28.021 14.1887-6.137 12.2008-18.771 20.5717-33.36 20.5717-10.2572 0-19.548-4.1381-26.294-10.836Z"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.5}
      d="M83 70.6197c4.7115-14.0678 17.839-23.9244 31.633-23.9244 12.119 0 21.889 5.9017 28.021 14.1887-6.137 12.2008-18.771 20.5717-33.36 20.5717-10.2572 0-19.548-4.1381-26.294-10.836Z"
    />
    <path
      fill="#fff"
      d="M104.619 65.2566h11.133v11.8752l-3.57-.0001v4.6835h-3.897l-4.991-9.9882h5.205l-3.88-6.5704Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="m108.285 81.8152-4.991-9.9882h5.205l-3.88-6.5704h11.133v11.8752l-3.57-.0001v4.6835"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M83 70.6197c4.7115-14.0678 17.839-23.9244 31.633-23.9244 12.119 0 21.889 5.9017 28.021 14.1887-6.137 12.2008-18.771 20.5717-33.36 20.5717-10.2572 0-19.548-4.1381-26.294-10.836Z"
    />
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.994}
      d="M114.174 57.608c-10.109 2.7614-20.543-3.195-23.3044-13.3041-2.7614-10.109 3.1951-20.5426 13.3044-23.304 10.109-2.7613 20.542 3.1951 23.304 13.3041 2.761 10.1091-3.195 20.5426-13.304 23.304Z"
    />
    <path
      fill="#1D1D1D"
      d="M116.727 39.6852c-1.118-.0701-1.952-1.2628-1.864-2.6639.087-1.4012 1.065-2.4803 2.182-2.4102 1.118.0701 1.953 1.2628 1.865 2.664-.088 1.4012-1.065 2.4802-2.183 2.4101ZM102.322 38.5739c-1.117-.0701-1.952-1.2628-1.864-2.664.088-1.4011 1.065-2.4802 2.183-2.4101 1.117.0701 1.952 1.2628 1.864 2.664-.088 1.4011-1.065 2.4802-2.183 2.4101Z"
    />
    <path
      fill="#FFB938"
      d="M99.5507 44.2719c-1.6186.6963-3.4952-.0514-4.1915-1.67-.6963-1.6186.0514-3.4952 1.67-4.1915 1.6186-.6963 3.4948.0514 4.1918 1.67.696 1.6186-.052 3.4952-1.6703 4.1915ZM120.793 46.3911c-1.619.6962-3.495-.0515-4.191-1.6701-.697-1.6186.051-3.4952 1.67-4.1915 1.618-.6962 3.495.0514 4.191 1.6701.696 1.6186-.051 3.4952-1.67 4.1915Z"
    />
    <path
      fill="#1D1D1D"
      d="M103.481 42.4065c-.259 3.1425 2.063 5.9134 5.209 6.2029 3.147.2895 5.935-2.0113 6.254-5.1482l-11.463-1.0547Z"
    />
    <path
      fill="#DFB0ED"
      d="m104.403 17.8894 4.864 10.165c0-4.6945 6.514-4.8084 9.472-4.3533v-3.4263c-3.795-3.5876-10.525-4.3195-14.336-2.3854Z"
    />
    <path
      fill="#1D1D1D"
      d="m109.267 28.0544-1.35.6461a1.4968 1.4968 0 0 0 2.847-.6461h-1.497Zm-4.864-10.165-.677-1.3348-1.304.6618.631 1.3191 1.35-.6461Zm14.336 2.3854h1.497v-.6447l-.469-.443-1.028 1.0877Zm0 3.4263-.228 1.4795 1.725.2653v-1.7448h-1.497Zm-8.122 3.7072-4.864-10.165-2.7 1.2922 4.864 10.165 2.7-1.2922Zm-5.537-8.184c1.557-.7898 3.859-1.09 6.278-.7321 2.405.356 4.728 1.3345 6.352 2.8703l2.057-2.1754c-2.17-2.0518-5.109-3.233-7.971-3.6564-2.847-.4214-5.815-.1205-8.07 1.0239l1.354 2.6697Zm12.162 1.0505v3.4263h2.994v-3.4263h-2.994Zm1.724 1.9468c-1.6-.2462-4.2-.3524-6.475.2834-1.146.3203-2.321.8596-3.224 1.7718-.939.9491-1.497 2.2169-1.497 3.7776h2.994c0-.7866.256-1.2934.631-1.6717.411-.4153 1.049-.7563 1.901-.9944 1.722-.481 3.857-.4166 5.215-.2077l.455-2.959Z"
    />
  </svg>
);

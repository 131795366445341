import type * as React from 'react';
export const GolferIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={139}
    height={120}
    fill="none"
    viewBox="0 0 139 120"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M28.9539 117.399H133.868V65.4912H28.9539C14.5794 65.4912 3 77.0707 3 91.4451 3 105.82 14.6793 117.399 28.9539 117.399Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M23.7628 91.4447V14.9805"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M23.7628 4.6v20.1642l23.4584-10.3816L23.7628 4.6Z"
    />
    <path
      fill="#1D1D1D"
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="M27.6559 95.3393c2.1501 0 3.8931-1.743 3.8931-3.8931s-1.743-3.8931-3.8931-3.8931-3.8931 1.743-3.8931 3.8931 1.743 3.8931 3.8931 3.8931Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="M87.7475 84.763c-1.1979 1.2977-1.1979 3.2942 0 4.492.8984.8984 2.2959 1.1979 3.3939.6988l8.0857-3.1943 5.5899-5.6899-8.9839.1996c-2.9947 0-5.8895 1.2977-8.0856 3.4938ZM119.991 91.75c1.797-.0998 3.095-1.4973 3.095-3.2941 0-1.2977-.799-2.3958-1.997-2.8949l-8.085-3.1943-7.986.2995 6.688 6.0892c2.196 1.9964 5.191 3.0945 8.285 2.9946Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="M107.195 87.725c9.544-1.4153 15.858-12.1562 14.103-23.9903-1.755-11.8341-10.915-20.2802-20.458-18.8649-9.544 1.4153-15.8579 12.1562-14.1029 23.9903 1.755 11.8341 10.9144 20.2802 20.4579 18.8649Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M92.3412 62.0029c7.0874 2.2959 14.6738 2.8949 21.9608 1.5972 9.483-1.5972 16.571-9.4832 17.27-19.0662.698-9.583-5.191-18.3674-14.375-21.3621h-.1c-6.987-2.2959-14.474-2.795-21.7611-1.5971h-.0998c-9.4832 1.5971-16.5706 9.4831-17.2694 19.0661-.5989 9.583 5.2906 18.3674 14.3745 21.3621Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="m106.018 46.7307-4.392-.2994M87.2504 37.6466l1.7968 3.8931-4.1925.6988M90.2473 48.3297c.7986 1.697 2.5954 2.5954 4.3922 2.2959"
    />
    <path
      fill="#F9AA9E"
      d="m106.716 55.0144-5.191 38.8311c-.499 3.8931-2.7948 7.2875-6.2886 9.1835-2.2959 1.298-4.9911 1.797-7.5865 1.398l-1.2977-.2c-2.5954-.299-4.3922-2.695-4.0927-5.2906.2994-2.5954 2.6952-4.3922 5.2906-4.0927l7.9858 1.098.7986.0999c2.6952.3993 5.0905-1.4974 5.4905-4.1926"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="m106.716 55.0144-5.191 38.8311c-.499 3.8931-2.7948 7.2875-6.2886 9.1835-2.2959 1.298-4.9911 1.797-7.5865 1.398l-1.2977-.2c-2.5954-.299-4.3922-2.695-4.0927-5.2906.2994-2.5954 2.6952-4.3922 5.2906-4.0927l7.9858 1.098.7986.0999c2.6952.3993 5.0905-1.4974 5.4905-4.1926"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="M105.318 78.3751c-1.597.1997-2.995-.9982-3.194-2.5954-.1-1.098.399-2.2959 1.397-2.795l6.888-3.9929c2.595-.2995 4.292 2.795 2.595 4.7915l-.598.6987c-1.797 2.1961-4.293 3.5936-7.088 3.8931Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="M102.724 77.7757c1.497.4991 3.194-.3993 3.693-1.8966.3-1.0981 0-2.2959-.798-3.0945l-5.8896-5.1908c-2.4956-.7986-4.6917 1.8966-3.4938 4.1925l.3993.7986c1.1978 2.4956 3.4941 4.2924 6.0891 5.1908Z"
    />
    <path
      fill="#fff"
      d="M112.805 33.1547c1.697-3.9929 1.497-8.5847-.899-12.378-2.395-3.7933-6.488-5.8896-10.78-6.0892-1.6974 3.9929-1.4978 8.5848.898 12.378 2.396 3.8931 6.488 5.9894 10.781 6.0892Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M112.805 33.1547c1.697-3.9929 1.497-8.5847-.899-12.378-2.395-3.7933-6.488-5.8896-10.78-6.0892-1.6974 3.9929-1.4978 8.5848.898 12.378 2.396 3.8931 6.488 5.9894 10.781 6.0892Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M112.805 33.1547c1.697-3.9929 1.497-8.5847-.899-12.378-2.395-3.7933-6.488-5.8896-10.78-6.0892-1.6974 3.9929-1.4978 8.5848.898 12.378 2.396 3.8931 6.488 5.9894 10.781 6.0892Z"
    />
    <path
      fill="#fff"
      d="M112.804 33.1538c6.189.1996 11.479-2.0963 13.775-6.5883 2.296-4.4921 1.098-10.0821-2.595-15.0733-6.189-.1996-11.48 2.0963-13.776 6.5883-2.296 4.4921-1.098 10.1819 2.596 15.0733Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M112.804 33.1538c6.189.1996 11.479-2.0963 13.775-6.5883 2.296-4.4921 1.098-10.0821-2.595-15.0733-6.189-.1996-11.48 2.0963-13.776 6.5883-2.296 4.4921-1.098 10.1819 2.596 15.0733Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M112.804 33.1538c6.189.1996 11.479-2.0963 13.775-6.5883 2.296-4.4921 1.098-10.0821-2.595-15.0733-6.189-.1996-11.48 2.0963-13.776 6.5883-2.296 4.4921-1.098 10.1819 2.596 15.0733Z"
    />
    <path
      fill="#fff"
      d="M112.804 33.1542c2.695 3.394 6.988 5.5901 11.779 5.2906 4.792-.1997 8.885-2.6952 11.28-6.3887-2.695-3.3939-6.987-5.59-11.779-5.2906-4.791.2995-8.884 2.7951-11.28 6.3887Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.5}
      d="M112.804 33.1542c2.695 3.394 6.988 5.5901 11.779 5.2906 4.792-.1997 8.885-2.6952 11.28-6.3887-2.695-3.3939-6.987-5.59-11.779-5.2906-4.791.2995-8.884 2.7951-11.28 6.3887Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M112.804 33.1542c2.695 3.394 6.988 5.5901 11.779 5.2906 4.792-.1997 8.885-2.6952 11.28-6.3887-2.695-3.3939-6.987-5.59-11.779-5.2906-4.791.2995-8.884 2.7951-11.28 6.3887Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.01}
      d="M58.1033 100.728c2.977 0 5.3904-2.4139 5.3904-5.3909 0-2.9771-2.4134-5.3904-5.3904-5.3904-2.9771 0-5.3905 2.4133-5.3905 5.3904 0 2.977 2.4134 5.3909 5.3905 5.3909Z"
    />
  </svg>
);

import type * as React from 'react';
export const MarieKondoIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={191}
    height={164}
    fill="none"
    viewBox="0 0 191 164"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#1D1D1D"
      d="M94.7108 162.396c20.8382 0 37.7312-2.111 37.7312-4.716s-16.893-4.716-37.7312-4.716c-20.8386 0-37.7316 2.111-37.7316 4.716s16.893 4.716 37.7316 4.716Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={3}
      d="M146.507 35.9419c-6.904 1.3146-11.801 5.614-13.387 7.5993l6.779 12.1233c8.331-4.3216 7.216-2.1631 17.556-6.7094 10.339-4.5463-2.318-14.6564-10.948-13.0132ZM85.7259 14.1645c3.9712 5.7985 4.4155 12.3004 4.1413 14.8266L76.513 32.813c-2.8637-8.937-3.5944-6.6202-7.7248-17.1325-4.1305-10.5122 11.9737-8.764 16.9377-1.516Z"
    />
    <path
      fill="#fff"
      d="M138.453 123.826c6.867 5.471 6.304 9.286 3.495 12.538-2.81 3.252-6.86 4.635-13.737-.927"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.25}
      d="M138.453 123.826c6.867 5.471 6.304 9.286 3.495 12.538-2.81 3.252-6.86 4.635-13.737-.927"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M138.453 123.826c6.867 5.471 6.304 9.286 3.495 12.538-2.81 3.252-6.86 4.635-13.737-.927"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M85.3942 140.378c-42.4481-8.759-46.4907-45.817-35.0365-78.1584 5.3899-15.2186 22.2347-46.4907 59.9663-32.3413 31.067 11.6502 37.732 32.3413 36.384 61.3138-1.347 28.9729-18.866 57.9449-61.3138 49.1859Z"
    />
    <path
      fill="#fff"
      d="M175.529 104.31c-1.39 1.787-6.702 2.482-6.702 2.482s3.895.592 6.205 2.482c2.731 2.234 2.731 8.192 2.731 8.192s.745-5.709 2.482-7.695c1.738-1.986 7.447-2.483 7.447-2.483s-5.858-.595-7.447-2.978c-1.588-2.383-2.482-7.9439-2.482-7.9439s-.844 6.1559-2.234 7.9439Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M175.529 104.31c-1.39 1.787-6.702 2.482-6.702 2.482s3.895.592 6.205 2.482c2.731 2.234 2.731 8.192 2.731 8.192s.745-5.709 2.482-7.695c1.738-1.986 7.447-2.483 7.447-2.483s-5.858-.595-7.447-2.978c-1.588-2.383-2.482-7.9439-2.482-7.9439s-.844 6.1559-2.234 7.9439Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M175.529 104.31c-1.39 1.787-6.702 2.482-6.702 2.482s3.895.592 6.205 2.482c2.731 2.234 2.731 8.192 2.731 8.192s.745-5.709 2.482-7.695c1.738-1.986 7.447-2.483 7.447-2.483s-5.858-.595-7.447-2.978c-1.588-2.383-2.482-7.9439-2.482-7.9439s-.844 6.1559-2.234 7.9439Z"
    />
    <path
      fill="#fff"
      d="M24.6733 16.5411c1.3901-1.7873 6.7023-2.4824 6.7023-2.4824s-3.8953-.5918-6.2058-2.4823c-2.7306-2.2341-2.7306-8.1918-2.7306-8.1918s-.7447 5.7094-2.4824 7.6953c-1.7376 1.9859-7.447 2.4823-7.447 2.4823s5.8583.5958 7.447 2.9789c1.5887 2.383 2.4824 7.9435 2.4824 7.9435s.844-6.1563 2.2341-7.9435Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M24.6733 16.5411c1.3901-1.7873 6.7023-2.4824 6.7023-2.4824s-3.8953-.5918-6.2058-2.4823c-2.7306-2.2341-2.7306-8.1918-2.7306-8.1918s-.7447 5.7094-2.4824 7.6953c-1.7376 1.9859-7.447 2.4823-7.447 2.4823s5.8583.5958 7.447 2.9789c1.5887 2.383 2.4824 7.9435 2.4824 7.9435s.844-6.1563 2.2341-7.9435Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M24.6733 16.5411c1.3901-1.7873 6.7023-2.4824 6.7023-2.4824s-3.8953-.5918-6.2058-2.4823c-2.7306-2.2341-2.7306-8.1918-2.7306-8.1918s-.7447 5.7094-2.4824 7.6953c-1.7376 1.9859-7.447 2.4823-7.447 2.4823s5.8583.5958 7.447 2.9789c1.5887 2.383 2.4824 7.9435 2.4824 7.9435s.844-6.1563 2.2341-7.9435Z"
    />
    <path
      fill="#fff"
      d="M15.2404 74.4862c1.3901-1.7873 6.7023-2.4824 6.7023-2.4824s-3.8952-.5918-6.2058-2.4823c-2.7306-2.2341-2.7306-8.1917-2.7306-8.1917s-.7447 5.7094-2.4823 7.6952c-1.7377 1.9859-7.447 2.4824-7.447 2.4824s5.8582.5957 7.447 2.9788c1.5886 2.383 2.4823 7.9435 2.4823 7.9435s.844-6.1562 2.2341-7.9435Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="M15.2404 74.4862c1.3901-1.7873 6.7023-2.4824 6.7023-2.4824s-3.8952-.5918-6.2058-2.4823c-2.7306-2.2341-2.7306-8.1917-2.7306-8.1917s-.7447 5.7094-2.4823 7.6952c-1.7377 1.9859-7.447 2.4824-7.447 2.4824s5.8582.5957 7.447 2.9788c1.5886 2.383 2.4823 7.9435 2.4823 7.9435s.844-6.1562 2.2341-7.9435Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M15.2404 74.4862c1.3901-1.7873 6.7023-2.4824 6.7023-2.4824s-3.8952-.5918-6.2058-2.4823c-2.7306-2.2341-2.7306-8.1917-2.7306-8.1917s-.7447 5.7094-2.4823 7.6952c-1.7377 1.9859-7.447 2.4824-7.447 2.4824s5.8582.5957 7.447 2.9788c1.5886 2.383 2.4823 7.9435 2.4823 7.9435s.844-6.1562 2.2341-7.9435Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M122.901 58.8299c.197-2.3906-1.263-4.6062-3.614-5.2812-2.792-.8245-5.743.6314-6.568 3.4234l-.486 1.4324M82.0578 46.3242c1.1885-2.0541 3.7327-3.0339 6.0465-2.2487 2.792.8245 4.248 3.776 3.4234 6.568l-.486 1.4324"
    />
    <path
      fill="#EBCDF4"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M85.3942 141.682c-42.4481-8.759-47.0408-46.5329-35.5865-78.8743 1.6771-4.7355 6.3461-16.1538 17.8846-27.1154 2.3077 8.0769 6.9231 22.5 29.4231 30 22.4996 7.5 41.1766-2.1201 46.1536-5.1923 4.039 8.6539 3.943 21.1692 3.439 31.9964-1.348 28.9726-18.866 57.9446-61.3138 49.1856Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M98.2681 138.202c7.3159-2.001 24.1209-9.808 27.0049-23.503l-2.252-1.625c-10.597 8.878-19.705 11.77-29.8855 14.821-.6686.2-1.0085.944-.7217 1.58l3.3788 7.494c.4336.962 1.458 1.512 2.4755 1.233Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M122.905 133.463c4.8-10.645.395-18.841-1.628-22.158 0 0-.374 9.133-5.552 15.26-4.828 5.711-9.133 8.527-18.1769 12.49-.6431.282-.8964 1.067-.5307 1.666l4.4586 7.31c.542.889 1.615 1.311 2.594.96 5.839-2.093 15.113-7.274 18.835-15.528Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M131.225 130.425c1.099-11.819-5.842-18.243-8.882-20.764 0 0 2.656 8.903-.299 16.503-2.757 7.09-5.968 11.219-13.365 18.024-.517.476-.502 1.3.039 1.749l6.739 5.587c.802.664 1.952.715 2.766.066 4.922-3.933 12.148-11.987 13.002-21.165Z"
    />
    <path
      fill="#fff"
      d="m66.4375 66.1566 49.4405 45.3914 9.43-2.654-54.4405-47.6776c-1.3747-1.204-3.4642-1.0697-4.6736.3003-1.1959 1.3549-1.0876 3.4177.2436 4.6399Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="m66.4375 66.1566 49.4405 45.3914 9.43-2.654-54.4405-47.6776c-1.3747-1.204-3.4642-1.0697-4.6736.3003-1.1959 1.3549-1.0876 3.4177.2436 4.6399Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m66.4375 66.1566 49.4405 45.3914 9.43-2.654-54.4405-47.6776c-1.3747-1.204-3.4642-1.0697-4.6736.3003-1.1959 1.3549-1.0876 3.4177.2436 4.6399Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={3}
      d="M119.463 105.859c-3.291.927-4.88 3.723-4.272 5.883.608 2.159 3.422 3.716 6.713 2.79 3.291-.926 4.88-3.723 4.272-5.882-.608-2.16-3.422-3.717-6.713-2.791Z"
    />
    <path
      fill="#fff"
      d="M115.657 84.1838c-7.303-2.1772-15.214.9833-15.213 5.4505.058 4.0399 5.418 7.5557 15.238 4.1924"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.25}
      d="M115.657 84.1838c-7.303-2.1772-15.214.9833-15.213 5.4505.058 4.0399 5.418 7.5557 15.238 4.1924"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M115.657 84.1838c-7.303-2.1772-15.214.9833-15.213 5.4505.058 4.0399 5.418 7.5557 15.238 4.1924"
    />
    <path
      fill="#fff"
      d="M56.5615 71.7367c5.2551 5.5181 13.6912 6.7069 15.9101 2.8297 1.9568-3.5349-.9473-9.2492-11.1401-11.2101"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.25}
      d="M56.5615 71.7367c5.2551 5.5181 13.6912 6.7069 15.9101 2.8297 1.9568-3.5349-.9473-9.2492-11.1401-11.2101"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M56.5615 71.7367c5.2551 5.5181 13.6912 6.7069 15.9101 2.8297 1.9568-3.5349-.9473-9.2492-11.1401-11.2101"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M101.326 59.0617c-.389 1.1484-1.6159 1.7078-2.7643 1.3182-1.1483-.3897-1.7078-1.6162-1.3181-2.7645"
    />
    <path
      fill="#fff"
      d="M71.2963 136.011c-3.369 7.412.7058 13.172 4.6674 14.007 4.0167.913 10.1431-1.519 10.8295-9.964"
    />
    <path
      fill="#B039D3"
      fillOpacity={0.25}
      d="M71.2963 136.011c-3.369 7.412.7058 13.172 4.6674 14.007 4.0167.913 10.1431-1.519 10.8295-9.964"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M71.2963 136.011c-3.369 7.412.7058 13.172 4.6674 14.007 4.0167.913 10.1431-1.519 10.8295-9.964"
    />
  </svg>
);

import type * as React from 'react';
export const MicroLightbulbIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={48}
    height={48}
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <circle
      cx={23.526}
      cy={25.635}
      r={8.471}
      fill="#fff"
      transform="rotate(9.668 23.526 25.635)"
    />
    <circle
      cx={23.526}
      cy={25.635}
      r={8.471}
      fill="#FFB938"
      fillOpacity={0.7}
      transform="rotate(9.668 23.526 25.635)"
    />
    <path
      fill="#fff"
      d="m19.7734 31.9341 5.2503.8944-1.2293 7.2158-5.2503-.8944 1.2293-7.2158Z"
    />
    <path
      fill="#FFB938"
      fillOpacity={0.7}
      d="m19.7734 31.9341 5.2503.8944-1.2293 7.2158-5.2503-.8944 1.2293-7.2158Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2}
      d="m19.0025 33.5413-.631 3.7036c-.1286 1.5758.9332 2.6507 2.5932 2.9335 1.66.2828 3.2593-.977 3.4182-1.9094l.666-3.8597"
    />
    <path
      stroke="#000"
      strokeWidth={2}
      d="M25.252 34.4434c.3062.0326.7673-.0549.9596-.1027 2.5648-.7932 4.7562-2.7072 5.8049-5.4037 1.8237-4.6893-.4993-9.9693-5.1887-11.793-4.6894-1.8238-9.9693.4993-11.7931 5.1887-1.6826 4.3265.1646 9.1557 4.1434 11.3094"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="m38.0433 19.5156 4.5391-1.543M11.4767 15.031l-3.5265-3.0652M20.4873 9.8879l-1.2344-5.051M30.3861 12.1656l3.3131-4.8673"
    />
  </svg>
);
